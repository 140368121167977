export class Command {
    constructor(
        public commandUuid: string = "",
        public name: string = "",
        public title: string = "",
        public position: string = "",
        public positionYear: string = "",
        public entryDate: string = "",
        public quote: string = "",
        public image: string = "",
        public command: boolean = false,
    ) { }
}

export class SubjectArea {
    constructor(
        public area: string = "",
        public description: string = "",
        public responsible: SubjectAreaResponsible = new SubjectAreaResponsible(),
        public mates: string[] = [],
    ) { }
}

export class SubjectAreaResponsible {
    constructor(
        public name: string = "",
        public image: string = "",
        public background: string = "",
    ) { }
}

export class MemberCounter {
    constructor(
        public name: string = "",
        public count: number = 0,
    ) { }
}