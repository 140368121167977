import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DataFiredepartmentTask } from 'src/app/dtos/data';
import { Command, MemberCounter } from 'src/app/dtos/member';
import { Vehicle } from 'src/app/dtos/vehicle';
import { DataService } from 'src/app/services/data.service';
import { MemberService } from 'src/app/services/member.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-about-us-main',
  templateUrl: './about-us-main.component.html',
  styleUrl: './about-us-main.component.scss'
})
export class AboutUsMainComponent implements OnInit {
  
  constructor(
    private memberService: MemberService,
    private elementRef: ElementRef,
    private dataService: DataService,
    private vehicleService: VehicleService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  memberCounters: MemberCounter[] = [];
  memberCommands: Command[] = [];

  firedepartmentChallanges: DataFiredepartmentTask[] = [];
  firedepartmentChallangesRandom: DataFiredepartmentTask[] = [];

  vehicles: Vehicle[] = [];
  vehiclesRandom: Vehicle[] = [];

  env = env;

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.loadMemberCounter();
      this.loadMemberCommands();

      this.loadFiredepartmentChallanges();
      this.loadVehicles();
    }
  }

  loadMemberCounter() {
    this.memberService.getCounter().subscribe(
      (response) => {
        this.memberCounters = this.renameCounters(response);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  loadMemberCommands() {
    this.memberService.getCommands().subscribe(
      (response) => {
        this.memberCommands = response;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  loadFiredepartmentChallanges() {
    this.dataService.getFiredepartmentTasks().subscribe(
      (response) => {
        this.firedepartmentChallanges = response;
        this.randomizeChallanges();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  loadVehicles() {
    this.vehicleService.getVehiclesList().subscribe(
      (response) => {
        this.vehicles = response;
        this.randomizeVehicles();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  renameCounters(counter: MemberCounter[]): MemberCounter[] {
    counter.forEach((counter) => {
      switch (counter.name) {
        case 'Jugend':
          counter.name = 'Jugendliche';
          break;
        case 'Aktiv':
          counter.name = 'Aktive';
          break;
        default:
          break;
      }
    });
    return counter;
  }

  commandNext() {
    var el = this.elementRef.nativeElement.querySelector('#persons');
    var scrollElNow = this.elementRef.nativeElement.querySelector('#personsScroll').scrollLeft;

    if(scrollElNow + el.offsetWidth / this.memberCommands.length > el.offsetWidth - el.offsetWidth / this.memberCommands.length) {
      this.elementRef.nativeElement.querySelector('#personsScroll').scroll({ left: 0, behavior: 'smooth' });
    } else {
      this.elementRef.nativeElement.querySelector('#personsScroll').scroll({ left: scrollElNow + el.offsetWidth / this.memberCommands.length, behavior: 'smooth' });
    }
    
  }

  commandPrev() {
    var el = this.elementRef.nativeElement.querySelector('#persons');
    var scrollElNow = this.elementRef.nativeElement.querySelector('#personsScroll').scrollLeft;
    
    if(scrollElNow - el.offsetWidth / this.memberCommands.length + 15 < 0) {
      this.elementRef.nativeElement.querySelector('#personsScroll').scroll({ left: el.offsetWidth - el.offsetWidth / this.memberCommands.length, behavior: 'smooth' });
    } else {
      this.elementRef.nativeElement.querySelector('#personsScroll').scroll({ left: scrollElNow - el.offsetWidth / this.memberCommands.length, behavior: 'smooth' });
    }
  }

  randomizeChallanges() {
    var challanges = this.firedepartmentChallanges;
    var max = challanges.length > 3 ? 3 : challanges.length;
    for (let i = 0; i < max; i++) {
      let random = challanges[Math.floor(Math.random() * challanges.length)];
      this.firedepartmentChallangesRandom.push(random);
      challanges = challanges.filter((challange) => challange !== random);
    }
  }

  randomizeVehicles() {
    var vehicles = this.vehicles;
    vehicles = vehicles.filter((vehicle) => vehicle.inUse);
    vehicles = vehicles.filter((vehicle) => vehicle.type === 'vehicle');
    var max = vehicles.length > 3 ? 3 : vehicles.length;
    for (let i = 0; i < max; i++) {
      let random = vehicles[Math.floor(Math.random() * vehicles.length)];
      this.vehiclesRandom.push(random);
      vehicles = vehicles.filter((vehicle) => vehicle !== random);
    }
  }
}
