import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID, Pipe, PipeTransform } from '@angular/core';
import { environment as cfg } from 'src/environments/environment';

@Pipe({
  name: 'textStyle'
})

export class TextStylePipe implements PipeTransform {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  transform(value: string, rootUrl: string= ''): string {
    if(isPlatformBrowser(this.platformId)) {
      // list
      value = value.replace(/\-\*\*([^*]+)\*\*\-/g, '<ul>$1</ul>');
      value = value.replace(/<ul>(.*?)<\/ul>/gs, function(match, group) {
          const lines = group.split('\n-').filter((line: string) => line.trim() !== '');
          const liItems = lines.map((line: string) => '<li>' + line + '</li>');
          return '<ul>' + liItems.join('') + '</ul>';
      });
  
      // spaces
      value = value.replace(/\n/g, '<br>');
      value = value.replace(/\r/g, '');
  
      // title
      value = value.replace(/\*2([^*]+)\*/g, '<h2>$1</h2>');
      value = value.replace(/\*1([^*]+)\*/g, '<h1>$1</h1>');
  
      // text
      value = value.replace(/\*_([^_]+)_\*/g, '<i>$1</i>');
      value = value.replace(/\*\*([^*]+)\*\*/g, '<b>$1</b>');
  
  
      // Image
      value = value.replace(/\[img:([^]+?)\]/g, '<img class="t___img" src="' + cfg.domain.cdn + rootUrl + '$1">');
  
      // Link
      value = value.replace(/\[link:([^]+?)\]\(([^)]+?)\)/g, '<a href="$1" target="_blank">$2</a>');
  
      // Escape
      value = value.replace(/\\([^\\])/g, '$1');
    }

    return value;
  }
}