import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { environment as cfg } from 'src/environments/environment';
import { DataService } from 'src/app/services/data.service';
import { Data } from 'src/app/dtos/data';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrl: './information.component.scss'
})
export class InformationComponent implements OnInit {
  constructor(
    public dataService: DataService,
    public elementRef: ElementRef,
    public renderer: Renderer2
  ) { }

  cfg = cfg;
  information: Data;
  public gallery = {
    "currentImage": "",
    "view": false,
  }

  ngOnInit(): void {
      this.getData();
  }

  @HostListener('click', ['$event'])
  onClick(event: any) {
    if (event.target.classList == "t___img") {
      this.openGallery(event.target.src);
    }
  }

  getData() {
    this.dataService.getData("information").subscribe((data: Data) => {
      this.information = data;
    });
  }

  openGallery(image: string) {
    this.gallery.currentImage = image;
    this.gallery.view = true;
  }

  getGalleryData(event: any) {
    this.gallery.currentImage = '';
    this.gallery.view = event.view;
  }
}
