import { Component, OnInit } from '@angular/core';
import { ExerciseService } from 'src/app/services/exercise.service';
import { Page } from 'src/app/dtos/page';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { environment as cfg } from 'src/environments/environment';
import { ExerciseLight } from 'src/app/dtos/exercise';
import { ArticlePreview } from 'src/app/dtos/article';
import { ArticlePreviewService } from 'src/app/services/mapper/article-preview.service';
@Component({
  selector: 'app-exercises',
  templateUrl: './exercises.component.html',
  styleUrl: './exercises.component.scss'
})
export class ExercisesComponent implements OnInit {

  constructor(
    private router: Router,
    private exerciseService: ExerciseService,
    private activatedRoute: ActivatedRoute,
    private articlePreviewService: ArticlePreviewService
  ) { }

  public cfg = cfg;

  public limit: number = 36;
  public page: Page = new Page(0, 1, 0, 0);

  public articles: ArticlePreview[] = [];
  public latestArticle: ArticlePreview = new ArticlePreview();

  ngOnInit(): void {
    this.articles = [];
    this.activatedRoute.data.subscribe(data => {
      this.loadContent(data['articles'].data, data['articles'].page);
    });
  }

  loadContent(articles: ArticlePreview[] | null = null, page: Page | null = null) {
    this.articles = [];
    if(!articles) {
      this.exerciseService.getExercisesList( this.page.currentPage, this.limit)
      .subscribe((response: ExerciseLight[]) => {
          response.forEach((exercise: ExerciseLight) => {
            this.articles.push(this.articlePreviewService.exercise(exercise));
          });
          this.loadPage();
      },
      (error) => {
          console.log(error);
      });
    } else {
      if(page) {
        this.page = page;
      }
      if(this.page.currentPage <= 1) {
        this.latestArticle = articles[0];
      }
      articles = articles.filter((article) => article !== this.latestArticle);
      this.articles = articles;
    }
  }

  loadPage() {
    this.exerciseService.getExercisesPage(this.page.currentPage, this.limit).subscribe(
      (response: Page) => {
        this.page = response;
        this.page.currentPage = response.currentPage;
      });
  }
  
  pageSelect(page: number = 1): void {
    this.articles = [];
    this.page.currentPage = page;
    this.router.navigate([], {
      queryParams: {
        page: page
      },
      queryParamsHandling: 'merge',
    });
    this.loadContent();
  }
}
