import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlIconService {

  public urlIconIndex = {
    youtube: ['youtube.com', 'youtu.be'],
    facebook: ['facebook.com', 'fb.com', 'fb.watch'],
    instagram: ['instagram.com'],
  }

  constructor() { }

  public getIcon(url: string): string {
    for (const key in this.urlIconIndex) {
      if (this.urlIconIndex.hasOwnProperty(key)) {
        const element = this.urlIconIndex[key as keyof typeof this.urlIconIndex];
        for (const urlElement of element) {
          if (url.includes(urlElement)) {
            return key;
          }
        }
      }
    }
    return 'default';
  }
}
