import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { OperationsComponent } from './components/operations/operations/operations.component';
import { environment as cfg } from 'src/environments/environment';
import { LatestMainComponent } from './components/latest/latest-main/latest-main.component';
import { VehiclesComponent } from './components/vehicle/vehicles/vehicles.component';
import { VehicleComponent } from './components/vehicle/vehicle/vehicle.component';
import { EventsComponent } from './components/latest/events/events.component';
import { ContactComponent } from './components/contact/contact.component';
import { ImprintComponent } from './components/leagal/imprint/imprint.component';
import { YearlyReportsComponent } from './components/latest/yearly-reports/yearly-reports.component';
import { HistoryComponent } from './components/about-us/history/history.component';
import { ErrorComponent } from './components/error/error.component';
import { FfjComponent } from './components/ffj/ffj/ffj.component';
import { FiredepartmentInformationComponent } from './components/about-us/firedepartment-information/firedepartment-information.component';
import { MemberComponent } from './components/about-us/member/member.component';
import { GdprComponent } from './components/leagal/gdpr/gdpr.component';
import { ExercisesComponent } from './components/latest/exercises/exercises.component';
import { NewsComponent } from './components/latest/news/news.component';
import { FfjReportsComponent } from './components/ffj/ffj-reports/ffj-reports.component';
import { InformationComponent } from './components/information/information.component';
import { AboutUsMainComponent } from './components/about-us/about-us-main/about-us-main.component';
import { operationResolver } from './resolver/operation.resolver';
import { newsResolver } from './resolver/news.resolver';
import { eventResolver } from './resolver/event.resolver';
import { exerciseResolver } from './resolver/exercise.resolver';
import { vehicleResolver } from './resolver/vehicle.resolver';
import { ffjReportResolver } from './resolver/ffj-report.resolver';
import { operationsResolver } from './resolver/operations.resolver';
import { newsListResolver } from './resolver/news-list.resolver';
import { ArticleComponent } from './components/article/article.component';
import { eventsResolver } from './resolver/events.resolver';
import { homeLatestResolver } from './resolver/home-latest.resolver';
import { exercisesResolver } from './resolver/exercises.resolver';
import { ffjReportsResolver } from './resolver/ffj-reports.resolver';

const routes: Routes = [
    { path: '', component: HomeComponent, title: cfg.meta.title },

    // operations
    { path: 'einsaetze', component: OperationsComponent, title: 'Einsätze - ' + cfg.meta.title, resolve: { articles: operationsResolver } },
    { path: 'einsaetze/:uuid', component: ArticleComponent, resolve: { article: operationResolver } },

    // latest
    { path: 'aktuelles', component: LatestMainComponent, title: 'Aktuelles - ' + cfg.meta.title },
    { path: 'aktuelles/news', component: NewsComponent, title: 'News - ' + cfg.meta.title, resolve: { articles: newsListResolver, latest: homeLatestResolver } },
    { path: 'aktuelles/news/:uuid', component: ArticleComponent, resolve: { article: newsResolver } },
    { path: 'aktuelles/veranstaltung', component: EventsComponent, title: 'Veranstalltungen - ' + cfg.meta.title, resolve: { articles: eventsResolver, latest: homeLatestResolver } },
    { path: 'aktuelles/veranstaltung/:uuid', component: ArticleComponent, resolve: { article: eventResolver } },
    { path: 'aktuelles/uebung', component: ExercisesComponent, title: 'Übungen - ' + cfg.meta.title, resolve: { articles: exercisesResolver } },
    { path: 'aktuelles/uebung/:uuid', component: ArticleComponent, resolve: { article: exerciseResolver } },
    { path: 'aktuelles/jahresbericht', component: YearlyReportsComponent, title: 'Jahresbericht - ' + cfg.meta.title },

    // about us
    { path: 'ueber-uns', component: AboutUsMainComponent, title: 'Über uns - ' + cfg.meta.title},
    { path: 'ueber-uns/geschichte', component: HistoryComponent, title: 'Geschichte - ' + cfg.meta.title },
    { path: 'ueber-uns/mannschaft', component: MemberComponent, title: 'Mannschaft - ' + cfg.meta.title },
    { path: 'ueber-uns/fahrzeuge', component: VehiclesComponent, title: 'Fahrzeuge - ' + cfg.meta.title },
    { path: 'ueber-uns/fahrzeuge/:id', component: VehicleComponent, resolve: { vehicle: vehicleResolver } },
    { path: 'ueber-uns/aufgaben-der-feuerwehr', component: FiredepartmentInformationComponent, title: 'Aufgaben der Feuerwehr - ' + cfg.meta.title },

    // information
    { path: 'informationen', component: InformationComponent, title: 'Informationen - ' + cfg.meta.title },

    // youth
    { path: 'jugend', component: FfjComponent, title: 'Jugend - ' + cfg.meta.title },
    { path: 'jugend/bericht', component: FfjReportsComponent, title: 'Berichte - ' + cfg.meta.title, resolve: { articles: ffjReportsResolver } },
    { path: 'jugend/bericht/:uuid', component: ArticleComponent, resolve: { article: ffjReportResolver }},

    // contact
    { path: 'kontakt', component: ContactComponent, title: 'Kontakt - ' + cfg.meta.title },

    // leagl
    { path: 'impressum', component: ImprintComponent, title: 'Impressum - ' + cfg.meta.title },
    { path: 'datenschutz', component: GdprComponent, title: 'Datenschutz - ' + cfg.meta.title },

    // redirects
    { path: 'einsätze', redirectTo: 'einsaetze' },
    { path: 'einsätze/:id', redirectTo: 'einsaetze/:id' },
    { path: 'aktuelles/übung', redirectTo: 'aktuelles/uebung' },
    { path: 'aktuelles/übung/:uuid', redirectTo: 'aktuelles/uebung/:uuid' },
    { path: 'über-uns', redirectTo: 'ueber-uns' },
    { path: 'über-uns/geschichte', redirectTo: 'ueber-uns/geschichte' },
    { path: 'über-uns/fahrzeuge', redirectTo: 'ueber-uns/fahrzeuge' },
    { path: 'über-uns/aufgaben-der-feuerwehr', redirectTo: 'ueber-uns/aufgaben-der-feuerwehr' },
    { path: 'über-uns/mannschaft', redirectTo: 'ueber-uns/mannschaft' },

    // error
    { path: '**', component: ErrorComponent, pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
