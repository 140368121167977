import { Injectable } from '@angular/core';
import { environment as cfg } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WebAnalytic } from '../dtos/analytic';

@Injectable({
  providedIn: 'root'
})
export class AnalyticService {

  constructor(
    public httpClient: HttpClient
  ) { }

  sendAnalytic(data: WebAnalytic): Observable<boolean> {
    return this.httpClient.post<boolean>(`${cfg.domain.api}system/statistic`, data);
  }
}
