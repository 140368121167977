<asset-title-bar
    [image]="'news'"
    [title]="'News'">
</asset-title-bar>

<ng-container *ngIf="page.currentPage <= 1 && latestArticle">
    <div class="latest-news">
        <article-preview-large [article]="latestArticle" [title]="'Aktuelle News'"></article-preview-large>
    </div>
</ng-container>

<div class="news">
    <asset-article-preview-list
        [(page)]="page"
        (pageChange)="pageSelect(page.currentPage + 1)"
        [articles]="articles"></asset-article-preview-list>
</div>