export class Data {
    constructor(
        public data: string = "",
        public gallery: string[] = [],
    ) {}
}

export class DataFiredepartmentTask {
    constructor(
        public taskUuid: string = "",
        public title: string = "",
        public description: string = "",
        public previewImage: string = "",
    ) {}
}