import { Injectable } from '@angular/core';
import { ArticlePreview } from 'src/app/dtos/article';
import { EventLight } from 'src/app/dtos/event';
import { ExerciseLight } from 'src/app/dtos/exercise';
import { FfjReportLight } from 'src/app/dtos/ffj';
import { NewsLight } from 'src/app/dtos/news';
import { OperationLight } from 'src/app/dtos/operation';

@Injectable({
  providedIn: 'root'
})
export class ArticlePreviewService {

  constructor() { }

  public operation(operation: OperationLight): ArticlePreview {
    let article = new ArticlePreview();
    article.uuid = operation.operationUuid;
    article.type = 'op';
    article.url = '/einsaetze/' + operation.operationUuid;
    article.title = operation.title;
    article.description = operation.report;
    article.previewImage = operation.previewImage;
    article.date = operation.date;
    article.opAlarm = operation.type;
    return article;
  }

  public event(data: EventLight): ArticlePreview {
    let article = new ArticlePreview();
    article.uuid = data.eventUuid;
    article.type = 'event';
    article.url = '/aktuelles/veranstaltung/' + data.eventUuid;
    article.title = data.title;
    article.description = data.description;
    article.previewImage = data.previewImage;
    article.date = data.date;
    return article;
  }

  public news(data: NewsLight): ArticlePreview {
    let article = new ArticlePreview();
    article.uuid = data.newsUuid;
    article.type = 'nw';
    article.url = '/aktuelles/news/' + data.newsUuid;
    article.title = data.title;
    article.description = data.description;
    article.previewImage = data.previewImage;
    article.date = data.date;
    return article;
  }

  public exercise(data: ExerciseLight): ArticlePreview {
    let article = new ArticlePreview();
    article.uuid = data.exerciseUuid;
    article.type = 'ex';
    article.url = '/aktuelles/uebung/' + data.exerciseUuid;
    article.title = data.title;
    article.description = data.report;
    article.previewImage = data.previewImage;
    article.date = data.date;
    return article;
  }

  public ffjReport(data: FfjReportLight): ArticlePreview {
    let article = new ArticlePreview();
    article.uuid = data.reportUuid;
    article.type = 'ffj';
    article.url = '/jugend/bericht/' + data.reportUuid;
    article.title = data.title;
    article.description = data.report;
    article.previewImage = data.previewImage;
    article.date = data.date;
    return article;
  }
}
