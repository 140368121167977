import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SsrService {

  constructor(
    private meta: Meta,
    private title: Title,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  setArticle(title: string, description: string, image: string, date: string | null = null) {
    this.title.setTitle(`${title} - ${environment.meta.title}`);
    this.meta.updateTag({ property: 'og:title', content: `${title} - ${environment.meta.title}` });
    this.meta.updateTag({ name: 'twitter:title', content: `${title} - ${environment.meta.title}` });

    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ name: 'twitter:description', content: description });

    this.meta.updateTag({ property: 'og:image', content: image });
    this.meta.updateTag({ name: 'twitter:image', content: image });

    if(date) {
      this.meta.updateTag({ property: 'og:updated_time', content: date });
      this.meta.updateTag({ name: 'twitter:label1', content: 'Datum' });
      this.meta.updateTag({ name: 'twitter:data1', content: date });
    }

    this.meta.updateTag({ property: 'og:type', content: 'article' });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });

    this.setJsonLd(title, description, image, date);
  }

  setKeywords(keywords: string[]) {
    this.meta.updateTag({ name: 'keywords', content: keywords.join(',') });
  }

  setDefault(description: string = environment.meta.description, image: string = environment.meta.image) {
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ name: 'twitter:description', content: description });

    this.meta.updateTag({ property: 'og:image', content: image });
    this.meta.updateTag({ name: 'twitter:image', content: image });

    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });

  }

  private setJsonLd(title: string, description: string, image: string, date: string | null = null) {
    if (date === null) {
      date = new Date().toISOString();
    }
    const dateConverted = new Date(date).toISOString();
    const jsonLd = {
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      "author": {
        "@type": "Organization",
        "name": environment.meta.title,
        "url": environment.domain.web
      },
      "publisher": {
        "@type": "Organization",
        "name": environment.meta.title,
        "url": environment.domain.web,
        "logo": {
          "@type": "ImageObject",
          "url": environment.domain.cdn + "assets/logo/ff_pdorf.svg"
        }
      },
      "headline": title,
      "description": description,
      "image": {
        "@type": "ImageObject",
        "url": image,
        "height": 1360,
        "width": 408
      },
      "datePublished": dateConverted,
      "dateModified": dateConverted
    };

    // check if is browser
    if (isPlatformBrowser(this.platformId)) {
      var lastScript = document.querySelector('script[type="application/ld+json"]');
      if(lastScript) {
        lastScript.remove();
      }

      let script = document.createElement('script');
      script.type = 'application/ld+json';
      script.text = JSON.stringify(jsonLd);
      document.head.appendChild(script);
    }
  }
}
