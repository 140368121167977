import { ResolveFn } from '@angular/router';
import { ArticlePreviewService } from '../services/mapper/article-preview.service';
import { inject } from '@angular/core';
import { catchError, map, of, switchMap } from 'rxjs';
import { ArticlePreviewObject } from '../dtos/article';
import { ExerciseService } from '../services/exercise.service';

export const exercisesResolver: ResolveFn<ArticlePreviewObject | null> = (route, state) => {
  var page = parseInt(route.queryParamMap.get('page') ?? "1");
  var limit = 36;

  const service = inject(ExerciseService);
  const mapper = inject(ArticlePreviewService);
  return service.getExercisesList(page, limit).pipe(
    switchMap((data) => {
      return service.getExercisesPage(page, limit).pipe(
        map((pageData) => {
          var articles = data.map((exercise) => {
            return mapper.exercise(exercise);
          });
          
          let articlePreviewObject = new ArticlePreviewObject(articles, pageData);
          return articlePreviewObject;
        }),
        catchError((error) => {
          console.log(error);
          return of(null);
        })
      );
    })
  );
};
