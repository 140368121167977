<ng-container *ngIf="datePickerOpen">
    <div class="datepicker">

        <div class="content">
            <div class="title">
                <h3>Zeit Auswählen</h3>
            </div>
            <div class="date">
                <div class="year">
                    <input type="number" [(ngModel)]="year" placeholder="Jahr">
        
                    <ng-container *ngFor="let year of yearPick">
                        <button (click)="changeYear(year)" [ngClass]="{'active': checkYear(year)}"> {{ year }} </button>
                    </ng-container>
                </div>
                <div class="month">
                    <button (click)="changeMonth(0)" [ngClass]="{'active': checkMonth(0)}">Alle</button>
                    <ng-container *ngFor="let month of months">
                        <button (click)="changeMonth(month.number)" [ngClass]="{'active': checkMonth(month.number)}"> {{ month.name }} </button>
                    </ng-container>
                </div>
            </div>
            <div class="buttons">
                <button (click)="closeDatePicker(true)">Ok</button>
                <button (click)="closeDatePicker(false)">Abbrechen</button>
            </div>
        </div>
        
    </div>

    <span class="close" (click)="closeDatePicker(false)"></span>
</ng-container>
