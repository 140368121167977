import { Injectable } from '@angular/core';
import { Article } from 'src/app/dtos/article';
import { SingleEntryNaviagtion } from 'src/app/dtos/default';
import { Event } from 'src/app/dtos/event';
import { Exercise } from 'src/app/dtos/exercise';
import { FfjReport } from 'src/app/dtos/ffj';
import { News } from 'src/app/dtos/news';
import { Operation } from 'src/app/dtos/operation';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor() { }

  public operation(data: Operation, navigation: SingleEntryNaviagtion | null = null): Article {
    const article = new Article();
    article.type = 'op';
    article.uuid = data.operationUuid;
    article.title = data.title;
    article.headline = data.reportShort;
    article.description = data.report;
    article.previewImage = data.previewImage;
    article.date = data.date;
    article.gallery = data.gallery;
    article.link = data.link;
    article.credit = data.credit;
    article.opAlarm = data.type;
    article.opElkosReport = data.elkosReport;
    article.opVehicles = data.vehicle;
    article.navigation = {
      previous: navigation?.previous ?? '',
      next: navigation?.next ?? '',
    };
    return article;
  }

  public event(data: Event, navigation: SingleEntryNaviagtion | null = null): Article {
    const article = new Article();
    article.type = 'ev';
    article.uuid = data.eventUuid;
    article.title = data.title;
    article.description = data.description;
    article.previewImage = data.previewImage;
    article.evDates = data.date;
    article.gallery = data.gallery;
    article.link = data.link;
    article.navigation = {
      previous: navigation?.previous ?? '',
      next: navigation?.next ?? '',
    };
    return article;
  }

  public exercise(data: Exercise, navigation: SingleEntryNaviagtion | null = null): Article {
    const article = new Article();
    article.type = 'ex';
    article.uuid = data.exerciseUuid;
    article.title = data.title;
    article.date = data.date;
    article.credit = data.credit;
    article.description = data.report;
    article.previewImage = data.previewImage;
    article.gallery = data.gallery;
    article.link = data.link;
    article.navigation = {
      previous: navigation?.previous ?? '',
      next: navigation?.next ?? '',
    };
    return article;
  }

  public news(data: News, navigation: SingleEntryNaviagtion | null = null): Article {
    const article = new Article();
    article.type = 'nw';
    article.uuid = data.newsUuid;
    article.title = data.title;
    article.description = data.description;
    article.previewImage = data.previewImage;
    article.date = data.date;
    article.gallery = data.gallery;
    article.link = data.link;
    article.nwStatus = data.important;
    article.navigation = {
      previous: navigation?.previous ?? '',
      next: navigation?.next ?? '',
    };
    return article;
  }

  public ffjReport(data: FfjReport, navigation: SingleEntryNaviagtion | null = null): Article {
    const article = new Article();
    article.type = 'fj';
    article.uuid = data.reportUuid;
    article.title = data.title;
    article.date = data.date;
    article.description = data.report;
    article.previewImage = data.previewImage;
    article.gallery = data.gallery;
    article.link = data.link;
    article.navigation = {
      previous: navigation?.previous ?? '',
      next: navigation?.next ?? '',
    };
    return article;
  }
}
