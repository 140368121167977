<asset-title-bar
    [image]="'ffj-reports'"
    [title]="'Berichte'">
</asset-title-bar>

<div class="reports">
    <asset-article-preview-list
        [(page)]="page"
        (pageChange)="pageSelect(page.currentPage + 1)"
        [articles]="articles">
    </asset-article-preview-list>
</div>