import { Link } from "./default";
import { VehicleLight } from "./vehicle";

export class OperationLight {
    constructor(
        public operationUuid: string = "",
        public date: string = "",
        public title: string = "",
        public report: string = "",
        public type: OperationType = new OperationType(),
        public previewImage: string = ""
    ) {}
}

export class Operation {
    constructor(
        public operationUuid: string = "",
        public date: string = "",
        public title: string = "",
        public report: string = "",
        public reportShort: string = "",
        public type: OperationType = new OperationType(),
        public elkosReport: string = "",
        public location: string = "",
        public credit: string = "",
        public previewImage: string = "",
        public vehicle: VehicleLight[] = [],
        public link: Link[] = [],
        public gallery: string[] = [],
    ) {}
}

export class OperationType {
    constructor(
        public type: string = "",
        public level: number = 0
    ) {}
}

export class OperationStatistics {
    constructor(
        public allOperations: number = 0,
        public fireOperations: number = 0,
        public technicalOperations: number = 0,
        public pollutantOperations: number = 0,
        public otherOperations: number = 0,
    ) {}
}