import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { environment as cfg } from 'src/environments/environment';
import { WastlService } from 'src/app/services/wastl.service';
import { WastlOperation } from 'src/app/dtos/wastl';
import { HomeLatest } from 'src/app/dtos/home';
import { HomeService } from 'src/app/services/home.service';
import { OperationLight, OperationType } from 'src/app/dtos/operation';
import { OperationService } from 'src/app/services/operation.service';
import { DataService } from 'src/app/services/data.service';
import { Data, DataFiredepartmentTask } from 'src/app/dtos/data';
import { isPlatformServer } from '@angular/common';
import { Command, MemberCounter } from 'src/app/dtos/member';
import { MemberService } from 'src/app/services/member.service';
import { Vehicle } from 'src/app/dtos/vehicle';
import { VehicleService } from 'src/app/services/vehicle.service';
import { NewsLight } from 'src/app/dtos/news';
import { NewsService } from 'src/app/services/news.service';
import { ArticlePreview } from 'src/app/dtos/article';
import { ArticlePreviewService } from 'src/app/services/mapper/article-preview.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  
  constructor(
    private wastlService: WastlService,
    private homeService: HomeService,
    private newsService: NewsService,
    private operationService: OperationService,
    private dataService: DataService,
    private memberService: MemberService,
    private vehicleService: VehicleService,
    private articlePreviewService: ArticlePreviewService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  
  public cfg: any = cfg;
  public scrollAnimation: boolean = false;

  public wastlOperations: WastlOperation[] = [];
  public wastlCurrentOperation: WastlOperation;
  public backgroundImages = {
    day: "background-day.jpg",
    night: "background-night.jpg",
    dayOperaion: "background-day_operation.jpg",
    nightOperaion: "background-night_operation.jpg"
  }
  public homeData: Data;

  public eventArticle: ArticlePreview = new ArticlePreview();
  public exerciseArticle: ArticlePreview = new ArticlePreview();
  public newsArticle: ArticlePreview = new ArticlePreview();
  public operationArticle: ArticlePreview[] = [];

  public latestInformationLoaded: boolean = false;
  public latestWarnings: NewsLight[] = [];
  public currentWarning: number = 0;

  public commands: Command[] = [];
  public command: Command = new Command();
  public counter: MemberCounter[] = [];
  public vehicles: Vehicle[] = [];
  public randomVehicle: Vehicle | null = null;
  public firedepartmentInfo: Data = new Data();

  ngOnInit(): void {
    if (!isPlatformServer(this.platformId)) {
      this.loadWastl();
      this.getLatest();
      this.getLatestOperation();
      this.loadData();
      this.getCommand();
      this.getCounter();
      this.getVehicles();
      this.getFiredepartmentTasks();
      this.getLastWarning();

      setTimeout(() => {
        this.scrollAnimation = true;
      }, 20000);
    }
  }

  loadData() {
    this.dataService.getData("home").subscribe(
      (data: Data) => {
        this.homeData = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getBackgroundImage(): string {
    var date = new Date();
    var hour = date.getHours();

    if(hour > 6 && hour < 20) {
      if(this.wastlOperations.length > 0) {
        return this.backgroundImages.dayOperaion;
      } else {
        return this.backgroundImages.day;
      }
    } else {
      if(this.wastlOperations.length > 0) {
        return this.backgroundImages.nightOperaion;
      } else {
        return this.backgroundImages.night;
      }
    }
  }

  loadWastl() {
    this.wastlService.getOperations('bezirk_14', 'Perchtoldsdorf').subscribe(
    // this.wastlService.getOperations().subscribe(
      (operations: WastlOperation[]) => {
        this.wastlOperations = operations;
        this.getCurrentOperation();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  private getLastWarning() {
    this.newsService.getWarnings().subscribe(
      (warnings: NewsLight[]) => {
        this.latestWarnings = warnings;
        this.currentWarning = 0;
        if(this.latestWarnings.length > 0) {
          setInterval(() => {
            this.changeCurrentWarning();
          }, 10000);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  private changeCurrentWarning() {
    if(this.currentWarning < this.latestWarnings.length - 1) {
      this.currentWarning++;
    } else {
      this.currentWarning = 0;
    }
  }

  getCurrentOperation() {
    this.wastlOperations.forEach(
      (operation: WastlOperation) => {
        if(this.wastlRealOperation(operation)) {
          this.wastlCurrentOperation = operation;
          return;
        }
      }
    )
  }

  wastlRealOperation(operation: WastlOperation): boolean {
    var type = operation.type.substring(0, 1).toLowerCase();
    var typeNumber = parseInt(operation.type.substring(1, 2));
    
    if((type == 's' || type == 't' || type == 'b' || type == 'z') && typeNumber > 0) {
      return true;
    } else {
      return false;
    }

  }

  wastlGetClass(operation: WastlOperation): string {
    return operation.type.substring(0, 1).toLowerCase();
  }

  getLatest() {
    this.homeService.getLatestInformation().subscribe(
      (latest: HomeLatest) => {
        // this.latestInformation = latest;
        this.eventArticle = this.articlePreviewService.event(latest.event);
        this.exerciseArticle = this.articlePreviewService.exercise(latest.exercise);
        this.newsArticle =this.articlePreviewService.news(latest.news);
        this.latestInformationLoaded = true;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getCommand() {
    this.memberService.getCommands().subscribe(
      (commands: Command[]) => {
        this.commands = commands;
        commands.forEach(
          (command: Command) => {
            if(command.command) {
              this.command = command;
            }
          }
        );
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getCounter() {
    this.memberService.getCounter().subscribe(
      (counter: MemberCounter[]) => {
        this.counter = counter;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getVehicles() {
    this.vehicleService.getVehiclesList().subscribe(
      (vehicles: Vehicle[]) => {
        this.vehicles = vehicles;
        
        // random vehicle that is inUse

        var inUseVehicles = vehicles.filter(vehicle => vehicle.inUse);
        var randomIndex = Math.floor(Math.random() * inUseVehicles.length);
        this.randomVehicle = inUseVehicles[randomIndex];
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getFiredepartmentTasks() {
    this.dataService.getData("firedepartment-information").subscribe(
      (data: Data) => {
        this.firedepartmentInfo = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getLatestOperation() {
    this.operationService.getOperations(undefined, undefined, undefined, 0, 3).subscribe(
      (operations: OperationLight[]) => {
        operations.forEach(
          (operation: OperationLight) => {
            this.operationArticle.push(this.articlePreviewService.operation(operation));
          }
        );
      },
      (error) => {
        console.log(error);
      }
    );
  }

  scrollToContent() {
    window.scrollTo({ top: innerHeight, behavior: 'smooth' });
  }

  operationType(type: OperationType): string {
    return type.type.toLowerCase();
  }

  dateToString(date: string): string {
    var dateObj = new Date(date);
    var dateString = dateObj.toLocaleDateString("de-AT", { year: 'numeric', month: 'long', day: 'numeric' });

    return dateString;
  }  
  
  getActiveYears(): string {
    var startDate = 1869;
    var currentYearRounded = "";
    
    var currentYear = new Date().getFullYear();
    var yearsActive = currentYear - startDate;
    var yearsActiveRounded = Math.floor(yearsActive / 50) * 50;
    currentYearRounded = yearsActiveRounded.toString();

    return currentYearRounded
  }
}
