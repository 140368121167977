<div class="list" *ngIf="!viewList">
    <ng-container *ngFor="let article of articles">
        <asset-article-preview [article]="article"></asset-article-preview>
    </ng-container>
</div>

<table class="list-table" *ngIf="viewList">
    <thead>
        <tr>
            <th>Titel</th>
            <th *ngIf="articles[0].type == 'op'">Alarmstufe</th>
            <th>Datum</th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let article of articles">
            <tr>
                <td>{{ article.title }}</td>
                <td *ngIf="article.type == 'op'" [ngClass]="[opType(article.opAlarm?.type ?? ''), 'type']">{{ article.opAlarm?.type }}{{ article.opAlarm?.level }}</td>
                <td>{{ formatDate(article.date) }}</td>
            </tr>
        </ng-container>
    </tbody>
</table>

<div class="page">
    <button
        *ngIf="page.allPages > 1"
        class="arrow"
        [disabled]="!(page.currentPage > 1)"
        (click)="goToPage(page.currentPage - 2)">
        <mat-icon>arrow_back_ios</mat-icon>
    </button>
    <ng-container *ngFor="let _page of getVisiblePages()">
        <button
            *ngIf="_page >= 0"
            [ngClass]="{ 'number': true, 'active': page.currentPage == _page }"
            (click)="goToPage(_page - 1)">{{ _page }}</button>
        <span *ngIf="_page < 0">...</span>
    </ng-container>
    <button
        *ngIf="page.allPages > 1"
        class="arrow"
        [disabled]="!(page.currentPage < (page.allPages))"
        (click)="goToPage(page.currentPage)">
        <mat-icon>arrow_forward_ios</mat-icon>
    </button>
</div>