import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './components/home/home.component';
import { NavComponent } from './components/assets/nav/nav.component';
import { FooterComponent } from './components/assets/footer/footer.component';
import { OperationsComponent } from './components/operations/operations/operations.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CountUpDirective } from './directive/count-up.directive';
import { DatePickerComponent } from './components/assets/module/date-picker/date-picker.component';
import { FormsModule } from '@angular/forms';
import { GalleryComponent } from './components/assets/module/gallery/gallery.component';
import { LatestMainComponent } from './components/latest/latest-main/latest-main.component';
import { VehicleComponent } from './components/vehicle/vehicle/vehicle.component';
import { VehiclesComponent } from './components/vehicle/vehicles/vehicles.component';
import { EventsComponent } from './components/latest/events/events.component';
import { ContactComponent } from './components/contact/contact.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { ImprintComponent } from './components/leagal/imprint/imprint.component';
import { YearlyReportsComponent } from './components/latest/yearly-reports/yearly-reports.component';
import { HistoryComponent } from './components/about-us/history/history.component';
import { TextStylePipe } from './pipe/text-style.pipe';
import { ErrorComponent } from './components/error/error.component';
import { FfjComponent } from './components/ffj/ffj/ffj.component';
import { FiredepartmentInformationComponent } from './components/about-us/firedepartment-information/firedepartment-information.component';
import { CookieComponent } from './components/assets/cookie/cookie.component';
import { MemberComponent } from './components/about-us/member/member.component';
import { GdprComponent } from './components/leagal/gdpr/gdpr.component';
import { ExercisesComponent } from './components/latest/exercises/exercises.component';
import { NewsComponent } from './components/latest/news/news.component';
import { FfjReportsComponent } from './components/ffj/ffj-reports/ffj-reports.component';
import { TitleBarComponent } from './components/assets/title-bar/title-bar.component';
import { InformationComponent } from './components/information/information.component';
import { AboutUsMainComponent } from './components/about-us/about-us-main/about-us-main.component';
import { ArticleComponent } from './components/article/article.component';
import { PopupShareComponent } from './components/assets/module/popup-share/popup-share.component';
import { ArticlePreviewComponent } from './components/assets/module/article-preview/article-preview.component';
import { ArticlePreviewListComponent } from './components/assets/module/article-preview-list/article-preview-list.component';
import { TabTrapDirective } from './directive/tab-trap.directive';
import { ArticlePreviewLargeComponent } from './components/assets/module/article-preview-large/article-preview-large.component';
import { TitleComponent } from './components/assets/title/title.component';
import { RtfComponent } from './components/assets/rtf/rtf.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavComponent,
    FooterComponent,
    OperationsComponent,
    CountUpDirective,
    DatePickerComponent,
    GalleryComponent,
    LatestMainComponent,
    VehicleComponent,
    VehiclesComponent,
    EventsComponent,
    ContactComponent,
    ImprintComponent,
    YearlyReportsComponent,
    HistoryComponent,
    TextStylePipe,
    ErrorComponent,
    FfjComponent,
    FiredepartmentInformationComponent,
    CookieComponent,
    MemberComponent,
    GdprComponent,
    ExercisesComponent,
    NewsComponent,
    FfjReportsComponent,
    TitleBarComponent,
    InformationComponent,
    AboutUsMainComponent,
    ArticleComponent,
    PopupShareComponent,
    ArticlePreviewComponent,
    ArticlePreviewListComponent,
    TabTrapDirective,
    ArticlePreviewLargeComponent,
    TitleComponent,
    RtfComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatIconModule,
    BrowserAnimationsModule,
    FormsModule,
    MatSelectModule,
    GoogleMapsModule
  ],
  providers: [
    provideClientHydration(),
    provideHttpClient(withInterceptorsFromDi())
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
