import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CookieSettings } from 'src/app/dtos/cookie';
import { LegalcookieService } from 'src/app/services/legalcookie.service';
import { LocalNotificationService } from 'src/app/services/local-notification.service';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent implements OnInit {

  constructor(
    public legalcookieService: LegalcookieService,
    public localNotificationService: LocalNotificationService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  cookies: {[key: string]: string} = {};
  cookieAccepted: boolean = true;
  cookieSettings: CookieSettings = new CookieSettings();


  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getCookies();
      this.getCookieSettings();
      this.checkCookie();

      this.localNotificationService.getNotifications().subscribe(message => {
        if(message.type == "component" && message.component == "cookieBanner") {
          if(message.data == true) {
            this.cookieAccepted = false;
          }
        }
      });
    }
  }

  getCookies() {
    var cookie = decodeURIComponent(document.cookie).split('; ');
    
    cookie.forEach((element) => {
      var cookie = element.split('=');
      this.cookies[cookie[0]] = cookie[1];
    });
  }

  getCookieSettings() {
    this.cookieSettings = this.legalcookieService.getCookieSettings();
  }

  checkCookie() {
    if(this.cookies["cookieAccpeted"] == "true") {
      this.cookieAccepted = true;
    } else {
      this.cookieAccepted = false;
    }
  }

  acceptCookies() {
    localStorage.setItem("cookieSettings", JSON.stringify(this.cookieSettings));
    document.cookie = "cookieAccpeted=true; expires=Fri, 31 Dec 2050 23:59:59 GMT; path=/";
    this.cookieAccepted = true;
  }

  declineCookies() {
    this.cookieSettings.googleMaps = false;

    localStorage.setItem("cookieSettings", JSON.stringify(this.cookieSettings));
    document.cookie = "cookieAccpeted=true; expires=Fri, 31 Dec 2050 23:59:59 GMT; path=/";
    this.cookieAccepted = true;
  }
}
