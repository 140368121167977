import { Component, Input } from '@angular/core';
import { environment as cfg } from 'src/environments/environment';

@Component({
  selector: 'asset-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrl: './title-bar.component.scss'
})
export class TitleBarComponent {
  constructor() {}

  @Input() title: string = '';
  @Input() image: string = 'default';

  cfg = cfg;
}
