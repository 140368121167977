<asset-title-bar
    [image]="'events'"
    [title]="'Veranstaltungen'">
</asset-title-bar>

<ng-container *ngIf="page.currentPage <= 1 && latestArticle">
    <div class="latest-event">
        <article-preview-large [article]="latestArticle" [title]="isInFuture(latestArticle.date) ? 'Nächste Veranstaltung' : 'Letzte Veranstaltung'"></article-preview-large>
    </div>
</ng-container>

<div class="events">
    <asset-article-preview-list
        [articles]="articles"
        [(page)]="page"
        (pageChange)="pageSelect(page.currentPage + 1)">
    </asset-article-preview-list>
</div>