import { Component, OnInit } from '@angular/core';
import { ArticlePreview } from 'src/app/dtos/article';
import { EventLight } from 'src/app/dtos/event';
import { ExerciseLight } from 'src/app/dtos/exercise';
import { NewsLight } from 'src/app/dtos/news';
import { YearlyReport } from 'src/app/dtos/yearly-report';
import { EventService } from 'src/app/services/event.service';
import { ExerciseService } from 'src/app/services/exercise.service';
import { ArticlePreviewService } from 'src/app/services/mapper/article-preview.service';
import { NewsService } from 'src/app/services/news.service';

@Component({
  selector: 'app-latest-main',
  templateUrl: './latest-main.component.html',
  styleUrls: ['./latest-main.component.scss']
})
export class LatestMainComponent implements OnInit {
  
    constructor(
      private newsService: NewsService,
      private eventService: EventService,
      private exerciseService: ExerciseService,
      private articlePreviewService: ArticlePreviewService
    ) { }

    public news: NewsLight[] = [];
    public events: EventLight[] = [];
    public exercises: ExerciseLight[] = [];

    public newsArticle: ArticlePreview[] = [];
    public eventArticle: ArticlePreview[] = [];
    public exerciseArticle: ArticlePreview[] = [];

    private listOptions = {
      page: 0,
      pageSize: 4
    };
  
    ngOnInit(): void {
      this.getNews();
      this.getEvents();
      this.getExercises();
    }

    getNews() {
      this.newsService.getNewsList(this.listOptions.page, this.listOptions.pageSize).subscribe((news) => {
        news.forEach((article) => {
          this.newsArticle.push(this.articlePreviewService.news(article));
        });
      });
    }

    getEvents() {
      this.eventService.getEvents(this.listOptions.page, this.listOptions.pageSize - 1).subscribe((events) => {
        events.forEach((event) => {
          this.eventArticle.push(this.articlePreviewService.event(event));
        });
      });
    }

    getExercises() {
      this.exerciseService.getExercisesList(this.listOptions.page, this.listOptions.pageSize).subscribe((exercises) => {
        exercises.forEach((exercise) => {
          this.exerciseArticle.push(this.articlePreviewService.exercise(exercise));
        });
      });
    }
}
