import { Injectable } from '@angular/core';
import { environment as cfg } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { YearlyReport } from '../dtos/yearly-report';

@Injectable({
  providedIn: 'root'
})
export class YearlyReportService {

  constructor(
    public httpClient: HttpClient
  ) { }

  getYearlyReports(): Observable<YearlyReport[]> {
    return this.httpClient.get<YearlyReport[]>(`${cfg.domain.api}feuerwehr/data/yearlyreports`);
  }
}
