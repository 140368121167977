export const environment = {
    "domain": {
        "api": "https://api.ff-perchtoldsdorf.at/public/",
        "cdn": "https://cdn.ff-perchtoldsdorf.at/public/",
        "auth": "https://auth.ff-perchtoldsdorf.at/",
        "web": "https://ff-perchtoldsdorf.at/"
    },

    "googleMapsApiKey": "AIzaSyBsptFcAxSp17RqD_w6-aTyNjlHsvmcCE8",
    "googleMpasMapId": "fb04a851d597031b",
    "facebookAppId": "864189085757985",
    "meta": {
        "title": "Freiwillige Feuerwehr Perchtoldsdorf",
        "description": "Freiwillige Feuerwehr Perchtoldsdorf - Offizielle Website der Freiwilligen Feuerwehr Perchtoldsdorf. Hier finden Sie alle Informationen rund um die Freiwillige Feuerwehr Perchtoldsdorf",
        "image": "https://cdn.ff-perchtoldsdorf.at/public/assets/meta/preview.jpg",
        "keywords": [
            "Feuerwehr",
            "Freiwillige Feuerwehr",
            "Freiwillige Feuerwehr Perchtoldsdorf",
            "FF Perchtoldsdorf",
            "Perchtoldsdorf",
            "Pdorf",
            "FF Pdrof",
            "FFP",
            "Österreich"
        ]
    }
};
