import { ResolveFn } from '@angular/router';
import { Vehicle } from '../dtos/vehicle';
import { VehicleService } from '../services/vehicle.service';
import { inject } from '@angular/core';
import { catchError, of } from 'rxjs';

export const vehicleResolver: ResolveFn<Vehicle | null> = (route, state) => {
  const vehicleService = inject(VehicleService);
  const id = route.paramMap.get('id') ?? '';
  return vehicleService.getVehicle(id, 'uuid').pipe(
    catchError((error) => {
      if (error.status === 404) {
        return of(null);
      } else {
        throw error;
      }
    })
  );
};
