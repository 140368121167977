import { Link } from "./default";
import { EventDate } from "./event";
import { OperationType } from "./operation";
import { Page } from "./page";
import { VehicleLight } from "./vehicle";

export class Article {
    constructor(
        // op = operation
        // nw = news
        // ev = event
        // ex = exercise
        // fj = ffj
        public uuid: string = '',
        public type: string = '',
        public title: string = '',
        public headline: string | null = null,
        public description: string = '',
        public previewImage: string = '',
        public location: string = '',
        public date: string = '',
        public gallery: string[] = [],
        public link: Link[] = [],
        public credit: string = '',

        public opAlarm: OperationType | null = null,
        public opElkosReport: string = '',
        public opVehicles: VehicleLight[] = [],

        public nwStatus: string = '',

        public evDates: EventDate[] = [],

        public navigation: ArticleNavigation = new ArticleNavigation()
    ) {}
}

export class ArticleNavigation {
    constructor(
        public previous: string = '',
        public next: string = ''
    ) {}
}

// preview
export class ArticlePreview {
    constructor (
        public uuid: string = '',
        public type: string = '',
        public url: string = '',
        public title: string = '',
        public description: string = '',
        public previewImage: string = '',
        public date: string = '',
        public opAlarm: OperationType | null = null,
    ) {}
}

export class ArticlePreviewObject {
    constructor(
        public data: ArticlePreview[] = [],
        public page: Page = new Page()
    ) {}
}