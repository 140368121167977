import { ResolveFn } from '@angular/router';
import { ArticlePreview } from '../dtos/article';
import { inject } from '@angular/core';
import { HomeService } from '../services/home.service';
import { ArticlePreviewService } from '../services/mapper/article-preview.service';
import { catchError, map, of, switchMap } from 'rxjs';

export const homeLatestResolver: ResolveFn<{ [key: string]: ArticlePreview }> = (route, state) => {
  const service = inject(HomeService);
  const mapper = inject(ArticlePreviewService);
  return service.getLatestInformation().pipe(
    switchMap((data) => {
      var articles: { [key: string]: ArticlePreview } = {};
      articles['event'] = mapper.event(data.event);
      articles['operation'] = mapper.operation(data.operation);
      articles['news'] = mapper.news(data.news);

      return of(articles);
    })
  );
};
