import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as cfg } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Page } from '../dtos/page';
import { Vehicle } from '../dtos/vehicle';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(
    private httpClient: HttpClient
  ) { }

  public getVehicle(id: string, type: string = 'uuid'): Observable<Vehicle> {
    if(type == 'id') {
      return this.httpClient.get<Vehicle>(`${cfg.domain.api}feuerwehr/vehicle?id=${id}`);
    } else {
      return this.httpClient.get<Vehicle>(`${cfg.domain.api}feuerwehr/vehicle?uuid=${id}`);
    }
  }

  public getVehiclesList(): Observable<Vehicle[]> {
    return this.httpClient.get<Vehicle[]>(`${cfg.domain.api}feuerwehr/vehicle/list`);
  }

}
