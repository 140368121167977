import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Page } from 'src/app/dtos/page';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { environment as cfg } from 'src/environments/environment';
import { EventService } from 'src/app/services/event.service';
import { EventLight } from 'src/app/dtos/event';
import { HomeService } from 'src/app/services/home.service';
import { HomeLatest } from 'src/app/dtos/home';
import { isPlatformBrowser } from '@angular/common';
import { ArticlePreview } from 'src/app/dtos/article';
import { ArticlePreviewService } from 'src/app/services/mapper/article-preview.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  constructor(
    private eventService: EventService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private homeService: HomeService,
    private articlePreviewService: ArticlePreviewService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  public cfg = cfg;

  public limit: number = 36;
  public page: Page = new Page(0, 1, 0, 0);

  public articles: ArticlePreview[] = [];
  public latestArticle: ArticlePreview = new ArticlePreview();

  ngOnInit(): void {
    this.articles = [];
    this.activatedRoute.data.subscribe(data => {
      this.loadContent(data['articles'].data, data['latest'].event, data['articles'].page);
      // this.getLatestEvent();
    });
  }

  loadContent(articles: ArticlePreview[] | null = null, latest: ArticlePreview | null = null, page: Page | null = null) {
    this.articles = [];
    if(!articles) {
      this.eventService.getEvents( this.page.currentPage, this.limit)
      .subscribe((response: EventLight[]) => {
          response.forEach((event: EventLight) => {
            this.articles.push(this.articlePreviewService.event(event));
          });
          this.loadPage();
      },
      (error) => {
          console.log(error);
      });
    } else {
      if(page) {
        this.page = page;
      }
      if(latest) {
        this.latestArticle = latest;
      }
      this.articles = articles;
    }
  }

  getLatestEvent() {
    this.homeService.getLatestInformation().subscribe(
      (response: HomeLatest) => {
        this.latestArticle = this.articlePreviewService.event(response.event);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  loadPage() {
    this.eventService.getEventsPage(this.page.currentPage, this.limit).subscribe(
      (response: Page) => {
        this.page = response;
        this.page.currentPage = response.currentPage;
        if(this.page.currentPage < 1) {
          this.getLatestEvent();
        }
      });
  } 
  
  pageSelect(page: number = 1): void {
    this.articles = [];
    this.page.currentPage = page;
    this.router.navigate([], {
      queryParams: {
        page: page
      },
      queryParamsHandling: 'merge',
    });
    this.loadContent();
  }

  isInFuture(date: string): boolean {
    var dateObj = new Date(date);
    var now = new Date();
    return dateObj > now;
  }
}
