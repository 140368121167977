import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { OperationLight, OperationStatistics, OperationType } from 'src/app/dtos/operation';
import { Page } from 'src/app/dtos/page';
import { OperationService } from 'src/app/services/operation.service';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { environment as cfg } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { ArticlePreview } from 'src/app/dtos/article';
import { ArticlePreviewService } from 'src/app/services/mapper/article-preview.service';

interface filterListType {
  type: string,
  friendlyName: string
}

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss']
})
export class OperationsComponent implements OnInit {

  constructor(
    private operationService: OperationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private articlePreviewService: ArticlePreviewService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  public year: number = new Date().getFullYear();
  public month: number = 0;
  public datePickerOpen: boolean = false;
  public parameter = this.activatedRoute.snapshot.queryParams;

  public filterTypeActive = 'all';
  public filterTypeList: filterListType[] = [
    {
      type: "all",
      friendlyName: "Alle"
    },
    {
      type: "t",
      friendlyName: "Technisch"
    },
    {
      type: "b",
      friendlyName: "Brand"
    },
    {
      type: "s",
      friendlyName: "Schadstoff"
    },
    {
      type: "other",
      friendlyName: "Sonstiges"
    }
  ];

  public cfg = cfg;

  public limit: number = 36;
  public page: Page = new Page(0, 1, 0, 0);

  public listTable: boolean = false;

  public articles: ArticlePreview[] = [];
  public operationStatistics: OperationStatistics = new OperationStatistics();

  ngOnInit(): void {
    this.articles = [];
    this.activatedRoute.data.subscribe(data => {
      if (isPlatformBrowser(this.platformId)) {
        this.loadParameter();
        this.loadStatistics();
      }
      this.loadContent(data['articles'].data, data['articles'].page);
    });
  }

  loadParameter() {
    if(this.parameter['year']) {
      this.year = this.parameter['year'];
    }
    if(this.parameter['month']) {
      this.month = this.parameter['month'];
    }
    if(this.parameter['page']) {
      this.page.currentPage = this.parameter['page'];
    }
    if(this.parameter['type']) {
      this.filterTypeActive = this.parameter['type'];
    }
  }

  loadContent(articles: ArticlePreview[] | null = null, page: Page | null = null) {
    this.articles = [];
    if(!articles) {
      this.operationService.getOperations(this.year, this.month, this.filterTypeActive, this.page.currentPage, this.limit)
      .subscribe((response: OperationLight[]) => {
          response.forEach((operation: OperationLight) => {
            this.articles.push(this.articlePreviewService.operation(operation));
          });
          this.loadPage();
      },
      (error) => {
          console.log(error);
      });
    } else {
      if(page) {
        this.page = page;
      }
      this.articles = articles;
    }
  }

  loadStatistics() {
    this.operationService.getOperationStatistics(this.year).subscribe((response: OperationStatistics) => {
      this.operationStatistics = response;
    });
  }

  loadPage() {
    this.operationService.getOperationPage(this.year, this.month, this.filterTypeActive, this.page.currentPage, this.limit).subscribe(
      (response: Page) => {
        this.page = response;
        this.page.currentPage = response.currentPage;
      });
  }
  
  selectYear(year: number) {
    this.year = year;
    this.ngOnInit();
  }

  operationType(type: OperationType): string {
    return type.type.toLowerCase();
  } 
  
  pageSelect(page: number = 1): void {
    this.articles = [];
    this.page.currentPage = page;
    this.router.navigate([], {
      queryParams: {
        year: this.year,
        month: this.month,
        page: page
      },
      queryParamsHandling: 'merge',
    });
    this.loadContent();
  }

  openDatePicker(): void {
    this.datePickerOpen = true;
  }

  getDate(): string {
    if(this.month == 0) {
      return this.year.toString();
    } else {
      var date = new Date(this.year, this.month, 0);
  
      return date.toLocaleDateString("de-AT", { year: 'numeric', month: 'long' });
    }
  }

  //changes
  getDatePickData(event: any) {   
    this.datePickerOpen = event.datePickerOpen;

    if(event.send) {
      //set data
      this.year = event.year;
      this.month = event.month;

      //add url parameter
      this.router.navigate([], {
        queryParams: {
          year: event.year,
          month: event.month,
          page: 1
        },
        queryParamsHandling: 'merge',
      });

      //set current page to 1
      this.page.currentPage = 1;

      //load content
      this.loadContent();
      this.loadStatistics();
    }
  }

  toggleDropdown(elementId: string): void {
    const element = document.getElementById(elementId);
    if (element) {
        element.classList.toggle('show');
    }
  }

  filterListType(listType: any): void {
    this.filterTypeActive = listType.type;
    this.pageSelect();
  }

  filterListTypeGet(): string {
    let friendlyName = "Alle";
    this.filterTypeList.forEach(fType => {
      if (fType.type === this.filterTypeActive) {
        friendlyName = fType.friendlyName;
      }
    });
    return friendlyName;
  }

  filterTypeChange(): void {
    this.router.navigate([], {
      queryParams: {
        page: 1,
        type: this.filterTypeActive
      },
      queryParamsHandling: 'merge',
    });

    this.loadContent();
  }

  statisticHover(id: string, add: boolean) {
    if(add) {
      document.getElementById(id)?.classList.add('hover');
    } else {
      document.getElementById(id)?.classList.remove('hover');
    }
  }
}
