<!-- <div class="title" [style]="'background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + cfg.domain.cdn + 'assets/background/contact.jpg);'">
    <h1>Kontakt</h1>
</div> -->

<div class="contact">
    <div class="background">
        <div class="maps" *ngIf="!mapsDisabled" id="map"></div>
        <div class="maps fake" *ngIf="mapsDisabled">
            <div Class="map">
                <img [src]="cfg.domain.cdn + 'assets/logo/ff_pdorf.svg'" (click)="openMapsLink()" class="label">
                <img [src]="cfg.domain.cdn + 'assets/maps/static/fdeep.jpg'" class="map_img">
            </div>
        </div>
    </div>

    <div class="content">
        <div class="box">
            <h1>Kontaktdaten</h1>
            <div class="informations">
                <h3><b>Notruf:</b></h3>
                <p>
                    <a href="tel:+4318654122" target="_blank"><b>01 / 86 54 122</b></a>
                </p>

                <h3>Addresse:</h3>
                <a href="https://maps.app.goo.gl/BD93etWEPbMS1CwS7" target="_blank">Donauwörtherstraße 29<br>
                2380 Perchtoldsdorf</a>
                
                <h3>Telefon:</h3>
                <p>
                    <a href="tel:+4318692334" target="_blank">01 / 869 23 34</a><br>
                    <sup>nicht dauerhaft besetzt</sup>
                </p>

                <h3>Wespentelefon:</h3>
                <p>
                    <a href="tel:+436648425270" target="_blank">0664 / 842 52 70</a><br>
                    <sup>von 1. Mai bis 30. September besetzt</sup>
                </p>

                <h3>E-Mail:</h3>
                <a href="mailto:perchtoldsdorf@feuerwehr.gv.at" target="_blank">perchtoldsdorf&commat;feuerwehr.gv.at</a>

                <h3>Presse:</h3>
                <a href="mailto:presse@ff-perchtoldsdorf.at" target="_blank">presse&commat;ff-perchtoldsdorf.at</a>
            </div>
            <div class="socialmedia">
                <a href="https://www.instagram.com/feuerwehr_perchtoldsdorf" target="_blank">
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"><defs><style>.cls-1{fill:#fff;}</style></defs><path class="cls-1" d="M295.42,6c-53.2,2.51-89.53,11-121.29,23.48-32.87,12.81-60.73,30-88.45,57.82S40.89,143,28.17,175.92c-12.31,31.83-20.65,68.19-23,121.42S2.3,367.68,2.56,503.46,3.42,656.26,6,709.6c2.54,53.19,11,89.51,23.48,121.28,12.83,32.87,30,60.72,57.83,88.45S143,964.09,176,976.83c31.8,12.29,68.17,20.67,121.39,23s70.35,2.87,206.09,2.61,152.83-.86,206.16-3.39S799.1,988,830.88,975.58c32.87-12.86,60.74-30,88.45-57.84S964.1,862,976.81,829.06c12.32-31.8,20.69-68.17,23-121.35,2.33-53.37,2.88-70.41,2.62-206.17s-.87-152.78-3.4-206.1-11-89.53-23.47-121.32c-12.85-32.87-30-60.7-57.82-88.45S862,40.87,829.07,28.19c-31.82-12.31-68.17-20.7-121.39-23S637.33,2.3,501.54,2.56,348.75,3.4,295.42,6m5.84,903.88c-48.75-2.12-75.22-10.22-92.86-17-23.36-9-40-19.88-57.58-37.29s-28.38-34.11-37.5-57.42c-6.85-17.64-15.1-44.08-17.38-92.83-2.48-52.69-3-68.51-3.29-202s.22-149.29,2.53-202c2.08-48.71,10.23-75.21,17-92.84,9-23.39,19.84-40,37.29-57.57s34.1-28.39,57.43-37.51c17.62-6.88,44.06-15.06,92.79-17.38,52.73-2.5,68.53-3,202-3.29s149.31.21,202.06,2.53c48.71,2.12,75.22,10.19,92.83,17,23.37,9,40,19.81,57.57,37.29s28.4,34.07,37.52,57.45c6.89,17.57,15.07,44,17.37,92.76,2.51,52.73,3.08,68.54,3.32,202s-.23,149.31-2.54,202c-2.13,48.75-10.21,75.23-17,92.89-9,23.35-19.85,40-37.31,57.56s-34.09,28.38-57.43,37.5c-17.6,6.87-44.07,15.07-92.76,17.39-52.73,2.48-68.53,3-202.05,3.29s-149.27-.25-202-2.53m407.6-674.61a60,60,0,1,0,59.88-60.1,60,60,0,0,0-59.88,60.1M245.77,503c.28,141.8,115.44,256.49,257.21,256.22S759.52,643.8,759.25,502,643.79,245.48,502,245.76,245.5,361.22,245.77,503m90.06-.18a166.67,166.67,0,1,1,167,166.34,166.65,166.65,0,0,1-167-166.34" transform="translate(-2.5 -2.5)"/></svg>
                    <p>Instagram</p>
                </a>
                <a href="https://www.facebook.com/ffperchtoldsdorf" target="_blank">
                    <svg id="Logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 700" class="facebook">
                        <g id="Logo-2" data-name="Logo">
                            <path id="Initiator" class="cls-1" d="m700,350C700,156.7,543.3,0,350,0S0,156.7,0,350c0,164.14,113,301.87,265.45,339.69v-232.74h-72.17v-106.95h72.17v-46.09c0-119.13,53.91-174.35,170.87-174.35,22.17,0,60.43,4.35,76.09,8.7v96.96c-8.26-.87-22.61-1.3-40.43-1.3-57.39,0-79.56,21.74-79.56,78.26v37.83h114.32l-19.64,106.95h-94.68v240.48c173.3-20.93,307.6-168.49,307.6-347.44Z"/>
                        </g>
                    </svg>
                    <p>Facebook</p>
                </a>
                <a href="https://www.youtube.com/@feuerwehrperchtoldsdorf" target="_blank">
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.48 85.04">
                        <path class="cls-1" d="m118.95,13.28c-1.4-5.23-5.51-9.34-10.74-10.74C98.73,0,60.74,0,60.74,0c0,0-37.99,0-47.46,2.54C8.05,3.94,3.94,8.05,2.54,13.28,0,22.75,0,42.52,0,42.52c0,0,0,19.77,2.54,29.24,1.4,5.23,5.51,9.34,10.74,10.74,9.47,2.54,47.46,2.54,47.46,2.54,0,0,37.99,0,47.46-2.54,5.23-1.4,9.34-5.51,10.74-10.74,2.54-9.47,2.54-29.24,2.54-29.24,0,0,0-19.77-2.54-29.24ZM48.59,60.74V24.3l31.56,18.22-31.56,18.22Z"/>
                    </svg>
                    <p>YouTube</p>
                </a>
                <a href="https://www.flickr.com/photos/197051003@N02" target="_blank">
                    <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2500 1160">
                        <path class="cls-1" d="m1340.2,579.97c0,320.23,259.66,579.9,579.95,579.9s579.85-259.66,579.85-579.9S2240.39.13,1920.15.13s-579.95,259.61-579.95,579.85h0Z"/>
                        <path class="cls-1" d="m0,579.97C0,900.21,259.61,1159.87,579.9,1159.87s579.9-259.66,579.9-579.9S900.19.13,579.9.13,0,259.74,0,579.97Z"/>
                    </svg>
                    <p>Flickr</p>
                </a>
            </div>
        </div>
    </div>
</div>

<!--
    Adresse:
    Donauwörtherstraße 29
    2380 Perchtoldsdorf

    Telefon:
    01 / 869 23 34

    Wespentelefon:
    0664 / 842 52 70

    E-Mail:
    perchtoldsdorf@feuerwehr.gv.at

    Presse:
    presse@ff-perchtoldsdorf.at
-->