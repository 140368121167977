<ng-container *ngIf="view">
    <div class="content" [tabTrap]="view">
        <div class="overlay">
            <div class="top">
                <button (click)="closeGallery()">
                    <mat-icon>arrow_back</mat-icon>
                </button>
            </div>
            <div class="navigate" *ngIf="isGallery()">
                <div class="left">
                    <span class="button">
                        <button (click)="previousImage()">
                            <span class="arrow" class="arrow"></span>
                        </button>
                    </span>
                </div>
                <div class="right">
                    <span class="button">
                        <button (click)="nextImage()">
                            <span class="arrow"></span>
                        </button>
                    </span>
                </div>
            </div>
        </div>

        <div class="view">
            <img [src]="currentImage">
        </div>

        <span class="void" (click)="closeGallery()"></span>
    </div>
</ng-container>