import { ResolveFn } from '@angular/router';
import { FfjReport } from '../dtos/ffj';
import { inject } from '@angular/core';
import { FfjService } from '../services/ffj.service';
import { catchError, map, of, switchMap } from 'rxjs';
import { Article } from '../dtos/article';
import { ArticleService } from '../services/mapper/article.service';

export const ffjReportResolver: ResolveFn<Article | null> = (route, state) => {
  const service = inject(FfjService);
  const mapper = inject(ArticleService);
  const id = route.paramMap.get('uuid') ?? '';

  return service.getReport(id).pipe(
    switchMap((report) => {
      
      return service.getNavigation(report.reportUuid).pipe(
        
        map((navigation) => {
          return mapper.ffjReport(report, navigation);
        })
      );
    }),
    // Fehlerbehandlung
    catchError((error) => {
      if (error.status === 404) {
        return of(null);
      } else {
        throw error;
      }
    })
  );
};
