import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { News } from '../dtos/news';
import { NewsService } from '../services/news.service';
import { catchError, of } from 'rxjs';

export const newsResolver: ResolveFn<News | null> = (route, state) => {
  const newsService = inject(NewsService);
  const uuid = route.paramMap.get('uuid') ?? '';
  return newsService.getNews(uuid).pipe(
    catchError((error) => {
      if (error.status === 404) {
        return of(null);
      } else {
        throw error;
      }
    })
  );
};
