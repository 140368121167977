<app-error *ngIf="error"></app-error>

<div class="card" *ngIf="vehicle && !error">
    <div class="top">
        <div class="information">
            <div class="text">
                <h1> {{ vehicle.name }} </h1>
                <h5> {{ vehicle.nameFull }} </h5>
            </div>
        </div>
        <div class="preview">
            <img [src]="vehicle.previewImage" (click)="openGallery(vehicle.previewImage, 'preview')">
        </div>
    </div>
    <div class="main">

        <div class="content">
            <div class="description">
                <!-- <h4>Beschreibung:</h4> -->
                <!-- <p> {{ vehicle.description }} </p> -->
                <p [innerHTML]="vehicle.description | textStyle:'feuerwehr/vehicle/'"></p>
            </div>
            <div class="info">
                <div class="list">
                    <ng-container *ngFor="let data of vehicleInformationList">
                        <span *ngIf="data.data">
                            <h4> {{ data.title }}: </h4>
                            <p> {{ data.data }} </p>
                        </span>
                    </ng-container>
                </div>
            </div>
            
        </div>

        <div class="gallery" *ngIf="vehicle.gallery.length > 0">
            <ng-container *ngFor="let image of vehicle.gallery">
                <a (click)="openGallery(image, 'gallery')">
                    <img [src]="image">
                </a>
            </ng-container>
        </div>
        
    </div>
</div>
<view-gallery
    [gallery]="galleryImages"
    [currentImage]="gallery.currentImage"
    [view]="gallery.view"
    (dataEmit)="getGalleryData($event)"></view-gallery>