import { Component, Input } from '@angular/core';
import { ArticlePreview } from 'src/app/dtos/article';

@Component({
  selector: 'asset-article-preview',
  templateUrl: './article-preview.component.html',
  styleUrl: './article-preview.component.scss'
})
export class ArticlePreviewComponent {
  @Input() article: ArticlePreview = new ArticlePreview();

  operationType(type: string): string {
    return type.toLowerCase();
  }

  formatDate(date: string): string {
    let d = new Date(date);
    return d.toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' });
  }
}
