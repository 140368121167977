import { Component, OnInit } from '@angular/core';
import { environment as cfg } from 'src/environments/environment';
import { VehicleService } from 'src/app/services/vehicle.service';
import { Vehicle } from 'src/app/dtos/vehicle';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent implements OnInit {

  constructor(
    public vehicleService: VehicleService
  ) { }

  public cfg = cfg;
  public vehicles: Vehicle[];
  public vehiclesUse: Vehicle[] = [];
  public vehiclesOld: Vehicle[] = [];
  public accessoryUse: Vehicle[] = [];
  public accessoryOld: Vehicle[] = [];
  public oldStuff: Vehicle[] = [];

  ngOnInit(): void {
    this.loadVehicles()
  }

  loadVehicles() {
    this.vehicleService.getVehiclesList().subscribe(
      (response: Vehicle[]) => {
        this.vehicles = response;

        this.vehicles.forEach(vehicle => {
          if(vehicle.inUse && vehicle.type == "vehicle") {
            this.vehiclesUse.push(vehicle);
          } else if(vehicle.inUse && vehicle.type == "accessory") {
            this.accessoryUse.push(vehicle);
          } else if(!vehicle.inUse && vehicle.type == "vehicle") {
            this.vehiclesOld.push(vehicle);
            this.oldStuff.push(vehicle);
          } else if(!vehicle.inUse && vehicle.type == "accessory") {
            this.accessoryOld.push(vehicle);
            this.oldStuff.push(vehicle);
          }
        });
      }
    );
  }
}
