<asset-title-bar
    [image]="'latest'"
    [title]="'Aktuelles'">
</asset-title-bar>

<div class="lists">
    <div>
        <asset-title [title]="'News'" [url]="'/aktuelles/news'"></asset-title>

        <div class="list">
            <div class="elements">
                <ng-container *ngFor="let news of newsArticle">
                    <asset-article-preview [article]="news"></asset-article-preview>
                </ng-container>
            </div>
            <div class="overlay">
                <div class="more" routerLink="/aktuelles/news">
                    <!-- <div class="arrow">
                        <span class="line"></span>
                        <span class="head"></span>
                    </div>
                    <p>Mehr</p> -->
                </div>
            </div>
        </div>
    </div>

    <div>
        <asset-title [title]="'Veranstaltungen'" [url]="'/aktuelles/veranstaltung'"></asset-title>

        <div class="list">
            <div class="elements">
                <ng-container *ngFor="let event of eventArticle">
                    <asset-article-preview [article]="event"></asset-article-preview>
                </ng-container>
            </div>
            <div class="overlay">
                <div class="more" routerLink="/aktuelles/veranstaltung">
                    <!-- <div class="arrow">
                        <span class="line"></span>
                        <span class="head"></span>
                    </div>
                    <p>Mehr</p> -->
                </div>
            </div>
        </div>
    </div>

    <div>
        <asset-title [title]="'Übungen'" [url]="'/aktuelles/uebung'"></asset-title>

        <div class="list">
            <div class="elements">
                <ng-container *ngFor="let exercise of exerciseArticle">
                    <asset-article-preview [article]="exercise"></asset-article-preview>
                </ng-container>
            </div>
            <div class="overlay">
                <div class="more" routerLink="/aktuelles/uebung">
                    <!-- <div class="arrow">
                        <span class="line"></span>
                        <span class="head"></span>
                    </div>
                    <p>Mehr</p> -->
                </div>
            </div>
        </div>
    </div>
</div>