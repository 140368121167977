import { Component, OnInit } from '@angular/core';
import { FfjLeader, FfjRegistration, FfjReportLight } from 'src/app/dtos/ffj';
import { FfjService } from 'src/app/services/ffj.service';
import { environment as cfg } from 'src/environments/environment';
import { Data } from 'src/app/dtos/data';
import { DataService } from 'src/app/services/data.service';
import { WebServiceService } from 'src/app/services/web-service.service';
import { ArticlePreviewService } from 'src/app/services/mapper/article-preview.service';
import { ArticlePreview } from 'src/app/dtos/article';

@Component({
  selector: 'app-ffj',
  templateUrl: './ffj.component.html',
  styleUrls: ['./ffj.component.scss']
})
export class FfjComponent implements OnInit {
  constructor(
    private ffjService: FfjService,
    private dataService: DataService,
    private webServiceService: WebServiceService,
    private articlePreviewService: ArticlePreviewService
  ) {}

  cfg = cfg;

  registrationFormular: FfjRegistration = new FfjRegistration();
  registrationDsgvo: boolean = false;

  data: Data = new Data("", []);
  leaderList: FfjLeader[] = [];
  reportArticle: ArticlePreview[] = [];
  public gallery = {
    "currentImage": "",
    "view": false,
  }

  registrationActive: boolean = false;
  
  error = {
    "registration": false,
  }

  ngOnInit(): void {
    this.getData();
    this.getLeaderList();
    this.getReports();
    this.checkRegistrationService();
  }

  sendRegistraion() {
    if(this.registrationFormular.gender
      && this.registrationFormular.firstname
      && this.registrationFormular.lastname
      && this.registrationFormular.email
      && this.registrationFormular.birthdate
      && this.registrationFormular.address.city
      && this.registrationFormular.address.street
      && this.registrationFormular.address.housenumber
      && this.registrationFormular.address.zip
      && this.registrationDsgvo) {
        this.ffjService.registration(this.registrationFormular).subscribe(
          (result) => {
            if(result) {
              this.registrationDsgvo = false;
              this.registrationFormular = new FfjRegistration();
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      console.log("form not complete!");
      this.error.registration = true;
    }
  }

  getData() {
    this.dataService.getData("ffj").subscribe((data: Data) => {
      this.data = data;
    });
  }

  getReports() {
    this.ffjService.getReportsList(1, 4).subscribe(
      (result) => {
        result.forEach((report: FfjReportLight) => {
          this.reportArticle.push(this.articlePreviewService.ffjReport(report));
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getLeaderList() {
    this.ffjService.getLeaderList().subscribe(
      (result) => {
        this.leaderList = result;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  dateToString(date: string): string {
    var dateObj = new Date(date);
    var dateString = dateObj.toLocaleDateString("de-AT", { year: 'numeric', month: 'long', day: 'numeric' });

    return dateString;
  }  

  openGallery(image: string) {
    this.gallery.currentImage = image;
    this.gallery.view = true;
  }

  getGalleryData(event: any) {
    this.gallery.currentImage = '';
    this.gallery.view = event.view;
  }

  checkRegistrationService() {
    this.webServiceService.checkService("ffj_registration").subscribe(
      (result) => {
        this.registrationActive = result;
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
