<section class="top" [ngStyle]="{'background': 'linear-gradient(#00000090, #00000090),url(' + cfg.domain.cdn + 'assets/background/home/' + getBackgroundImage() + ')'}">
    <div class="top">

        <div class="title">
            <div class="img">
                <img [src]="cfg.domain.cdn + 'assets/logo/ff_pdorf.svg'">
            </div>
            <span class="line"></span>
            <div class="text">
                <h1>Freiwillige Feuerwehr <br> Perchtoldsdorf</h1>
            </div>
        </div>

        <div class="bottom">
            <div class="warnings" *ngIf="latestWarnings">
                <ng-container *ngFor="let warning of latestWarnings; let i = index">
                    <a
                        [ngClass]="['warning-single', warning.important]"
                        [class.active]="i == currentWarning"
                        [class.single]="latestWarnings.length == 1"
                        [routerLink]="'/aktuelles/news/' + warning.newsUuid">
                        <div class="statusbar">
                            <span class="bar" *ngIf="i == currentWarning"></span>
                        </div>
                        <div class="image">
                            <img [src]="warning.previewImage">
                        </div>
                        <div class="information">
                            <h3>{{ warning.title }}</h3>
                            <p class="description">{{ warning.description }}</p>
                            <p class="date">{{ dateToString(warning.date) }}</p>
                        </div>
                    </a>
                </ng-container>
            </div>

            <div class="arrow"[ngClass]="{'arrow': true, 'active': scrollAnimation}" (click)="scrollToContent()">
                <span></span>
                <span></span>
            </div>
            <div class="current-operation" [ngClass]="[wastlGetClass(wastlCurrentOperation), 'always-present-class']" *ngIf="wastlCurrentOperation">
                <h4> {{ wastlCurrentOperation.message }} </h4>
                <p> Seit {{ wastlCurrentOperation.date }} {{ wastlCurrentOperation.time }} im Einsatz</p>
    
                <p class="type"> {{ wastlCurrentOperation.type }} </p>
            </div>
        </div>
    </div>
</section>

<section class="information" id="info">
    <div class="welcome">
        <!-- <h1>Herzlich Willkommen</h1>
        <p>bei der Freiwilligen Feuerwehr Perchtoldsdorf. Wir sind Tag und Nacht ehrenamtlich für die Sicherheit unserer Gemeinde im Einsatz. Entdecken Sie hier unsere vielfältigen Aktivitäten, lernen Sie unsere Mannschaft kennen und erfahren Sie, wie auch Sie Teil unserer starken Gemeinschaft werden können. Sicherheit, Kameradschaft, Engagement - erleben Sie die Werte, die uns seit über {{ getActiveYears() }} Jahren verbinden.<br>Willkommen bei der Freiwilligen Feuerwehr Perchtoldsdorf - Ihren "Rettern" von nebenan!</p> -->
        <p [innerHTML]="homeData.data | textStyle:'feuerwehr/data/home/'" *ngIf="homeData"></p>
    </div>
    <div class="list" *ngIf="latestInformationLoaded">

        <asset-title [title]="'Einsätze'" [url]="'/einsaetze'"></asset-title>
        <div class="operation-list">
            <ng-container *ngFor="let operation of operationArticle">
                <asset-article-preview [article]="operation"></asset-article-preview>
            </ng-container>
        </div>

        <asset-title [title]="'News'" [url]="'/aktuelles/news'"></asset-title>
        <article-preview-large [article]="newsArticle"></article-preview-large>

        <asset-title [title]="'Veranstaltungen'" [url]="'/aktuelles/veranstaltung'"></asset-title>
        <article-preview-large [article]="eventArticle"></article-preview-large>

        <asset-title [title]="'Übungen'" [url]="'/aktuelles/uebung'"></asset-title>
        <article-preview-large [article]="exerciseArticle"></article-preview-large>

    </div>    
</section>

<section class="about-us">
    <asset-title [title]="'Über uns'" [url]="'/ueber-uns'"></asset-title>

    <div class="block">
        <a class="command bg__el" *ngIf="command.commandUuid" routerLink="/ueber-uns/mannschaft">
    
            <div class="preview">
                <div class="img">
                    <img [src]="command.image" class="person">
                    <div class="background">
                        <img [src]="cfg.domain.cdn + 'feuerwehr/member/background_commands.jpg'">
                    </div>
                </div>
            </div>
            <div class="text">
                <div class="name">
                    <h4>{{ command.title }}</h4>
                    <h3>{{ command.name }}</h3>
                </div>
                <p class="position">{{ command.position }}</p>
                <p class="quote">
                    <span>&bdquo;</span>{{ command.quote }}<span>&rdquo;</span>
                </p>
            </div>
    
        </a>

        <a class="counters" routerLink="/ueber-uns/mannschaft">
            <div class="list">
                <ng-container *ngFor="let count of counter">
                    <div class="counter">
                        <p>{{ count.count }}</p>
                        <h6>{{ count.name }}</h6>
                    </div>
                </ng-container>
            </div>
        </a>
    </div>

    <div class="block">
        <a class="firedepartmenttask" routerLink="/ueber-uns/aufgaben-der-feuerwehr">
            <asset-rtf
                class="text"
                [text]="firedepartmentInfo.data"
                [base]="'firedepartment-information'"
                [smallView]="true">
            </asset-rtf>
        </a>
        <a class="vehicle bg__el" *ngIf="randomVehicle" [routerLink]="'/ueber-uns/fahrzeuge/' + randomVehicle.vehicleUuid">
            <div class="text">
                <h3>{{ randomVehicle.name }}</h3>
            </div>
            <div class="img">
                <img [src]="randomVehicle.previewImage">
            </div>
        </a>
    </div>
</section>