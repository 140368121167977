<div class="text">
    <h1>Impressum</h1>

    <h3>Medieninhaber:</h3>
    <p>Freiwillige Feuerwehr Perchtoldsdorf</p>
    <p>Donauwörtherstraße 29</p>
    <p>2380 Perchtoldsdorf</p>
    <p>Österreich</p>
    <p>Telefon: <a href="tel:+4318692334">01 / 869 23 34</a></p>
    <p>E-Mail: <a href="mailto:perchtoldsdorf@feuerwehr.gv.at">perchtoldsdorf&commat;feuerwehr.gv.at</a></p>
    
    <h4>Organe laut §38 NÖFG: Kommandant {{ imprint.commander }}, Mitgliederversammlung</h4>
    <p>Die Inhalte dieser Seite wurden nach bester Sorgfalt bearbeitet und geprüft. Die Freiwillige Feuerwehr Perchtoldsdorf übernimmt jedoch keinerlei Gewähr oder Haftung für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen den Verfasser, die sich auf Schäden materieller oder ideeller Art beziehen, welche durch die Nutzung oder Nichtnutzung der Inhalte dieser Seite bzw. durch deren Nutzung verursacht wurden sind grundsätzlich ausgeschlossen.<br><br>
    
        Die Inhalte, Informationen und Links dieser Seite werden nur zum Informationsaustausch bereitgestellt und begründen keine kommerziellen oder beruflichen Beziehungen irgendwelcher Art und dienen ausschließlich informativen Zwecken.<br><br>
        
        Der Medieninhaber dieser Seite übernimmt keine Verantwortung oder Haftung für die Inhalte verlinkter Seiten, die nicht im Verantwortungsbereich der Freiwilligen Feuerwehr Perchtoldsdorf liegen. Für Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung verlinkter Seiten entstehen, haftet allein der Anbieter dieser Seiten, nicht derjenige, der lediglich über Links auf die jeweilige Veröffentlichung verweist.<br><br>
        
        Die Verfasser sind bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Fotos und Texte zu beachten, von ihm selbst erstellte Grafiken, Fotos und Texte zu nutzen oder auf lizenzfreie Grafiken, Fotos und Texte zurückzugreifen. Sollte sich auf dieser Seite dennoch eine ungekennzeichnete, aber durch fremdes Copyright geschützte Grafik, Foto oder Text befinden, so konnte das Urheberrecht vom Verfasser nicht festgestellt werden. Im Falle einer solchen unbeabsichtigten Copyrightverletzung wird der Verfasser das entsprechende Objekt sofort nach Benachrichtigung von der Seite entfernen bzw. mit dem entsprechenden Copyright kenntlich machen. Das Copyright für veröffentlichte, vom Verfasser selbst erstellte Grafiken, Fotos und Texte bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Fotos oder Texte in anderen elektronischen oder gedruckten Publikationen ist nur mit ausdrücklicher Zustimmung des Inhabers gestattet. Es wird zusätzlich auf den § 74(1) Urheberrechtsgesetz zum Schutz von Lichtbildern hingewiesen.<br><br>
        
        Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon nicht berührt.
    </p>
</div>
