<asset-title-bar
    [image]="'member'"
    [title]="'Mannschaft'">
</asset-title-bar>

<div class="introduction">
    <p [textContent]="data.data"></p>
</div>

<div class="member-img">
    <img [src]="cfg.domain.cdn + 'feuerwehr/member/members.jpg'" (click)="openGallery(cfg.domain.cdn + 'feuerwehr/member/members.jpg')">
</div>

<div class="counter">
    <div class="counters">
        <ng-container *ngFor="let count of counter">
            <span>
                <h1 [countUp]="count.count" [duration]="2500 + (counter.indexOf(count) * 20)"></h1>
                <h2>{{ count.name }}</h2>
            </span>
        </ng-container>
    </div>
</div>

<div class="command">
    <div class="scroll-hint">
        <div class="left">
            <span class="arrow" (click)="commandPrev()"></span>
        </div>

        <div class="right">
            <span class="arrow" (click)="commandNext()"></span>
        </div>
    </div>
    <div class="container" id="personsScroll">
        <div class="persons" id="persons">

            <ng-container *ngFor="let command of commands">
    
                <div class="person" id="person">
                    <div class="img">
                        <img [src]="command.image" class="person" (click)="openGallery(command.image)">
                        <div class="background">
                            <img [src]="cfg.domain.cdn + 'feuerwehr/member/background_commands.jpg'" class="background">
                        </div>
                    </div>
                    <div class="text">
                        <div class="head">
                            <h2>{{ command.title }}</h2>
                            <h1> {{ command.name }} </h1>
                        </div>
                        <h4 class="position"> {{ command.position }} </h4>
                        <div class="text">
                            <p *ngIf="command.positionYear">Seit {{ command.positionYear }}</p>
                            <p *ngIf="command.entryDate">Aktiv seit {{ command.entryDate }} </p>
                            <p class="quote" *ngIf="command.quote">
                                <span>&bdquo;</span>{{ command.quote }}<span>&rdquo;</span>
                            </p>
                        </div>
                    </div>
                </div>
    
            </ng-container>
    
        </div>
    </div>
    <div class="background">
    </div>
</div>



<div class="subject-areas">

    <div class="header">
        <h1>Sachgebiete</h1>
    </div>
    
    <ng-container *ngFor="let subjectArea of subjectAreas">
        <div class="area">
            <div class="information">
                <h1> {{ subjectArea.area }} </h1>
                <p> {{ subjectArea.description }} </p>
            </div>
            <div class="person">
                <div class="img">
                    <img [src]="subjectArea.responsible.image" class="port">
                    <div class="background">
                        <img [src]="subjectArea.responsible.background">
                    </div>
                </div>
                <h1> {{ subjectArea.responsible.name }} </h1>
                <div class="mates">
                    <p *ngFor="let mate of subjectArea.mates">
                        {{ mate }}
                    </p>
                </div>
            </div>
        </div>
    </ng-container>
    
</div>

<view-gallery
    [gallery]="gallery.gallery"
    [currentImage]="gallery.currentImage"
    [view]="gallery.view"
    (dataEmit)="getGalleryData($event)"></view-gallery>