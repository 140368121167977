import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Article } from 'src/app/dtos/article';
import { SsrService } from 'src/app/services/ssr.service';
import { UrlIconService } from 'src/app/services/url-icon.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrl: './article.component.scss'
})
export class ArticleComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public urlIconService: UrlIconService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private ssrService: SsrService
  ) { }

  enviroment = environment;
  isBrowser = isPlatformBrowser(this.platformId);

  public article: Article = new Article();
  private keywords: string[] = ["Einsatz"];

  public printInformations = {
    domain: environment.domain.web.replace('https://', '').replace("/", "")
  }

  public error = false;
  public popupShare = false;
  public gallery = {
    "currentImage": "",
    "view": false,
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(data => {

      if(isPlatformBrowser(this.platformId)) {
        if(data['article']) {
          this.article = data['article'];
          this.setupGallery();
        } else {
          this.error = true;
        }
      }
      this.setMetaTags(data);
    },
    error => {
      console.log(error);
      this.error = true;
    });
  }

  private setupGallery() {
    const preImageSplit = this.article.previewImage.split('/');
      if(preImageSplit[preImageSplit.length - 2] != 'default') {
        // preview image as first image in gallery
        this.article.gallery = this.article.gallery.reverse() as [ image: string ];
        this.article.gallery.push(this.article.previewImage);
        this.article.gallery = this.article.gallery.reverse() as [ image: string ];

        this.gallery.currentImage = this.article.previewImage;
      }
  }

  private setMetaTags(data: Data) {
    const metaData = data['article'];

    let description = "";
    if(metaData.headline) {
      description = metaData.headline;
    } else if(metaData.description) {
      description = metaData.description
    }
    
    this.ssrService.setArticle(metaData.title, description, metaData.previewImage, metaData.date)

    this.keywords.push(metaData.title);
    this.keywords.push(metaData.date);
    this.keywords.push(metaData.elkosReport);
    this.keywords.push(metaData.location);
    this.keywords.push(...environment.meta.keywords);
    this.ssrService.setKeywords(this.keywords);
  }

  convertDate(date: string, split: string = ''): string {
    var dateDate = new Date(date);
    if(split == 'min') {
      return dateDate.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
    } else {
      return dateDate.toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
    }
  }

  openGallery(image: string) {
    this.gallery.currentImage = image;
    this.gallery.view = true;
  }

  getGalleryData(event: any) {
    this.gallery.currentImage = '';
    this.gallery.view = event.view;
  }

  print() {
    window.print();
  }

  navigateTo(uuid: string) {
    var rootUrl = "";
    switch (this.article.type) {
      case "op":
        rootUrl = "/einsaetze";
        break;

      case "ev":
        rootUrl = "/aktuelles/veranstaltung";
        break;

      case "nw":
        rootUrl = "/aktuelles/news";
        break;

      case "ex":
        rootUrl = "/aktuelles/uebung";
        break;

      case "fj":
        rootUrl = "/jugend/bericht";
        break;
    }
    this.router.navigate([`${rootUrl}/${uuid}`]);
  }

  getUrl() {
    let url = window.location.href;
    return url.split('?')[0];
  }

}
