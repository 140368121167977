import { Component, OnInit } from '@angular/core';
import { LegalImprint } from 'src/app/dtos/legal';
import { LegalService } from 'src/app/services/legal.service';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent implements OnInit {
  
  constructor(
    private legalService: LegalService
  ) { }

  imprint: LegalImprint = new LegalImprint();

  ngOnInit(): void {
    this.getImprint();
  }

  getImprint() {
    this.legalService.getImprint().subscribe(
      (imprint) => {
        this.imprint = imprint;
      }
    );
  }
}
