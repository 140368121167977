import { Component } from '@angular/core';
import { LocalNotification } from 'src/app/dtos/local';
import { LocalNotificationService } from 'src/app/services/local-notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  constructor(
    public localNotificationService: LocalNotificationService
  ) {}

  public environment = environment;

  public date: Date = new Date();

  openCookieBanner() {
    var data: LocalNotification = new LocalNotification(
      "component",
      "cookieBanner",
      true,
      0
    );

    this.localNotificationService.sendMessage(data);
  }
}
