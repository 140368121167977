import { Component, ElementRef, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { environment as cfg } from 'src/environments/environment';
import { Command, MemberCounter, SubjectArea } from 'src/app/dtos/member';
import { MemberService } from 'src/app/services/member.service';
import { DataService } from 'src/app/services/data.service';
import { Data } from 'src/app/dtos/data';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss']
})
export class MemberComponent implements OnInit {

  constructor(
    public memberService: MemberService,
    public dataService: DataService,
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  public cfg = cfg;
  public gallery = {
    "currentImage": "",
    "view": false,
    "gallery": [] as string[]
  }
  public commands: Command[] = [];
  public data: Data = new Data("", []);
  public subjectAreas: SubjectArea[] = [];
  public counter: MemberCounter[] = [];

  ngOnInit(): void {
    if(isPlatformBrowser(this.platformId)) {
      this.loadData();
      this.loadCounter();
      this.loadCommands();
      this.loadSubjectAreas();
    }
  }

  loadData() {
    this.dataService.getData("member").subscribe(
      (data) => {
        this.data = data;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  loadCounter() {
    this.memberService.getCounter().subscribe(
      (data) => {
        this.counter = data;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  loadCommands() {
    this.memberService.getCommands().subscribe(
      (data: Command[]) => {
        this.commands = data;
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  loadSubjectAreas() {
    this.memberService.getSubjectAreas().subscribe(
      (data) => {
        this.subjectAreas = data;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  openGallery(image: string, gallery: string[] = [image]) {
    this.gallery.currentImage = image;
    this.gallery.gallery = gallery;
    this.gallery.view = true;
  }

  getGalleryData(event: any) {
    this.gallery.currentImage = '';
    this.gallery.view = event.view;
  }

  commandNext() {
    var el = this.elementRef.nativeElement.querySelector('#persons');
    var scrollElNow = this.elementRef.nativeElement.querySelector('#personsScroll').scrollLeft;

    if(scrollElNow + el.offsetWidth / this.commands.length > el.offsetWidth - el.offsetWidth / this.commands.length) {
      this.elementRef.nativeElement.querySelector('#personsScroll').scroll({ left: 0, behavior: 'smooth' });
    } else {
      this.elementRef.nativeElement.querySelector('#personsScroll').scroll({ left: scrollElNow + el.offsetWidth / this.commands.length, behavior: 'smooth' });
    }
    
  }

  commandPrev() {
    var el = this.elementRef.nativeElement.querySelector('#persons');
    var scrollElNow = this.elementRef.nativeElement.querySelector('#personsScroll').scrollLeft;
    
    if(scrollElNow - el.offsetWidth / this.commands.length + 15 < 0) {
      this.elementRef.nativeElement.querySelector('#personsScroll').scroll({ left: el.offsetWidth - el.offsetWidth / this.commands.length, behavior: 'smooth' });
    } else {
      this.elementRef.nativeElement.querySelector('#personsScroll').scroll({ left: scrollElNow - el.offsetWidth / this.commands.length, behavior: 'smooth' });
    }
  }
}
