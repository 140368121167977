<asset-title-bar
    [image]="'exercises'"
    [title]="'Übungen'">
</asset-title-bar>

<ng-container *ngIf="page.currentPage <= 1 && latestArticle">
    <div class="latest-exercise">
        <article-preview-large [article]="latestArticle" [title]="'Letzte Übung'"></article-preview-large>
    </div>
</ng-container>

<div class="exercises">
    <asset-article-preview-list
        [(page)]="page"
        (pageChange)="pageSelect(page.currentPage + 1)"
        [articles]="articles">
    </asset-article-preview-list>
</div>