<a class="article" [routerLink]="article.url">
    <div class="preview">
        <img [src]="article.previewImage">
    </div>
    <div class="text">
        <h5 *ngIf="title">{{ title }}</h5>
        <h1> {{ article.title }} </h1>
        <p [class.large]="!title"> {{ article.description }} </p>
        <h6 class="date"> {{ formatDate(article.date) }} </h6>
    </div>
</a>