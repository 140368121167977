<span
    class="void"
    [class.active]="view"
    (click)="closePopup()">
</span>

<div
    class="popup"
    [@openClose]="view ? 'open' : 'closed'"
    [class.active]="view"
    [tabTrap]="view"
    aria-hidden="true"
    aria-modal="true"
    (keydown.esc)="closePopup()"
    (@openClose.done)="animationDone($event)">
    <ng-container>
        <h1>Teilen</h1>

        <div class="link">
            <input type="text" [value]="url" [readOnly]="true" #urlValueCopy>
            <button (click)="copyInput()">
                <mat-icon>content_copy</mat-icon>
            </button>
        </div>
    
        <div class="services" [style.gridTemplateColumns]="'repeat(' + (4 + (checkShareAPI() ? 1 : 0)) + ', 1fr)'">
            <span>
                <a [href]="getFacebookLink()" target="_blank" [innerHTML]="svg['facebook']">
                </a>
            </span>
            <span>
                <a [href]="getXLink()" target="_blank" [innerHTML]="svg['x']">
                </a>
            </span>
            <span>
                <a [href]="getWhatsappLink()" target="_blank" [innerHTML]="svg['whatsapp']">
                </a>
            </span>
            <span>
                <a [href]="getMailLink()" target="_blank">
                    <mat-icon>email</mat-icon>
                </a>
            </span>
            <span *ngIf="checkShareAPI()">
                <a (click)="shareContent()">
                    <mat-icon>share</mat-icon>
                </a>
            </span>
        </div>
    </ng-container>
</div>