<div class="tabmenu">
    <button (click)="tabNavigation('main')">Hauptinhalt</button>
</div>
<nav #nav [class]="{'nav_open': !isMenuCollapsed}">
    <div class="info">
        <img [src]="cfg.domain.cdn + 'assets/logo/ff_pdorf.svg'" routerLink="/">
        <div class="nav_toggle">
            <div (click)="toggleMenu()" [class]="{'close': !isMenuCollapsed}">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
    <div class="nav">
        <ul [class]="{'open': !isMenuCollapsed}">
            <li><a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Startseite</a></li>
            <li><a routerLink="/einsaetze" routerLinkActive="active">Einsätze</a></li>
            <li>
                <a routerLink="/aktuelles" routerLinkActive="active">Aktuelles</a>
                <div>
                    <ul>
                        <li><a routerLink="/aktuelles/news" routerLinkActive="active">News</a></li>
                        <li><a routerLink="/aktuelles/veranstaltung" routerLinkActive="active">Veranstaltungen</a></li>
                        <li><a routerLink="/aktuelles/uebung" routerLinkActive="active">Übungen</a></li>
                        <li><a routerLink="/aktuelles/jahresbericht" routerLinkActive="active">Jahresbericht</a></li>
                    </ul>
                </div>
            </li>
            <li>
                <a routerLink="/ueber-uns" routerLinkActive="active">Über uns</a>
                <div>
                    <ul>
                        <li><a routerLink="/ueber-uns/geschichte" routerLinkActive="active">Geschichte</a></li>
                        <li><a routerLink="/ueber-uns/mannschaft" routerLinkActive="active">Mannschaft</a></li>
                        <li><a routerLink="/ueber-uns/aufgaben-der-feuerwehr" routerLinkActive="active">Aufgaben der Feuerwehr</a></li>
                        <li><a routerLink="/ueber-uns/fahrzeuge" routerLinkActive="active">Fahrzeuge</a></li>
                    </ul>
                </div>
            </li>
            <li>
                <a routerLink="/informationen" routerLinkActive="active">Informationen</a>
            </li>
            <li>
                <a routerLink="/jugend" routerLinkActive="active">Feuerwehrjugend</a>
            </li>
            <li><a routerLink="/kontakt" routerLinkActive="active">Kontakt</a></li>
        </ul>
    </div>
</nav>

