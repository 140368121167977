import { Injectable } from '@angular/core';
import { CookieSettings } from '../dtos/cookie';

@Injectable({
  providedIn: 'root'
})
export class LegalcookieService {

  constructor() { }

  getCookieSettings(): CookieSettings {
    var cookieSettings = localStorage.getItem("cookieSettings");
    if(cookieSettings != null) {
      return JSON.parse(cookieSettings);
    } else {
      return new CookieSettings();
    }
  }

  checkIfServiceAllowed(service: string): boolean {
    var cookieSettings = this.getCookieSettings();

    if(service == "googleMaps") {
      return cookieSettings.googleMaps;
    } else {
      return false;
    }
  }
}
