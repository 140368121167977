export class Link {
    constructor(
        public title: string,
        public url: string,
    ) {}
}

export class InformationList {
    constructor(
      public title: string,
      public data: string | number,
    ) {}
}

export class SingleEntryNaviagtion {
    constructor(
        public next: string,
        public previous: string,
    ) {}
}

export class Address {
    constructor(
        public zip: string,
        public city: string,
        public street: string,
        public housenumber: string,
    ) {}
}