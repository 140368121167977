import { Injectable } from '@angular/core';
import { environment as cfg } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Data, DataFiredepartmentTask } from '../dtos/data';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    public httpClient: HttpClient
  ) { }

  getData(type: string): Observable<Data> {
    return this.httpClient.get<Data>(`${cfg.domain.api}feuerwehr/data/text/` + type);
  }

  getFiredepartmentTasks(): Observable<DataFiredepartmentTask[]> {
    return this.httpClient.get<DataFiredepartmentTask[]>(`${cfg.domain.api}feuerwehr/data/firedepartment/task`);
  }
}
