import { Component, Input } from '@angular/core';

@Component({
  selector: 'asset-title',
  templateUrl: './title.component.html',
  styleUrl: './title.component.scss'
})
export class TitleComponent {
  @Input() title: string = '';
  @Input() url: string = '';
  @Input() width: number = 17;
}
