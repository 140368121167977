<asset-title-bar
    [image]="'information'"
    [title]="'Informationen'">
</asset-title-bar>

<div class="content" *ngIf="information">
    <p [innerHTML]="information.data | textStyle:'feuerwehr/data/information/'" class="dataText"></p>
    <div class="gallery">
        <a class="image" *ngFor="let image of information.gallery">
            <img [src]="image" (click)="openGallery(image)"/>
        </a>
    </div>
</div>
<view-gallery
    *ngIf="information"
    [gallery]="information.gallery"
    [currentImage]="gallery.currentImage"
    [view]="gallery.view"
    (dataEmit)="getGalleryData($event)"></view-gallery>