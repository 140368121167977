<asset-title-bar
    [image]="'about-us'"
    [title]="'Über uns'"></asset-title-bar>

<section class="member">
    <asset-title [title]="'Mannschaft'" [url]="'/ueber-uns/mannschaft'"></asset-title>

    <div class="counters" *ngIf="memberCounters.length > 0">
        <ng-container *ngFor="let counter of memberCounters">
            <div class="counter-box">
                <p class="counter" [countUp]="counter.count">{{ counter.count }}</p>
                <h4>{{ counter.name }}</h4>
            </div>
        </ng-container>
    </div>

    <div class="command">
        <div class="scroll-hint">
            <div class="left">
                <span class="arrow" (click)="commandPrev()"></span>
            </div>
    
            <div class="right">
                <span class="arrow" (click)="commandNext()"></span>
            </div>
        </div>
        <div class="container" id="personsScroll">
            <div class="persons" id="persons">
    
                <ng-container *ngFor="let command of memberCommands">
        
                    <div class="person" id="person">
                        <div class="img">
                            <img [src]="command.image" class="person">
                            <div class="background">
                                <img [src]="env.domain.cdn + 'feuerwehr/member/background_commands.jpg'" class="background">
                            </div>
                        </div>
                        <div class="text">
                            <div class="head">
                                <h2>{{ command.title }}</h2>
                                <h1> {{ command.name }} </h1>
                            </div>
                            <h4 class="position"> {{ command.position }} </h4>
                            <!-- <div class="text">
                                <p *ngIf="command.positionYear">Seit {{ command.positionYear }}</p>
                                <p *ngIf="command.entryDate">Aktiv seit {{ command.entryDate }} </p>
                                <p class="quote" *ngIf="command.quote">
                                    <span>&bdquo;</span>{{ command.quote }}<span>&rdquo;</span>
                                </p>
                            </div> -->
                        </div>
                    </div>
        
                </ng-container>
        
            </div>
        </div>
        <div class="background">
        </div>
    </div>
</section>

<section class="firedepartment">
    <asset-title [title]="'Aufgaben und Herausforderungen'" [url]="'/ueber-uns/aufgaben-der-feuerwehr'"></asset-title>
    <div class="challanges">
        <ng-container *ngFor="let challange of firedepartmentChallangesRandom">
            <div class="challange">
                <img [src]="challange.previewImage">
                <div class="text">
                    <h3>{{ challange.title }}</h3>
                    <p>{{ challange.description }}</p>
                </div>
            </div>
        </ng-container>
    </div>
</section>

<section class="vehicles">
    <asset-title [title]="'Fahrzeuge'" [url]="'/ueber-uns/fahrzeuge'"></asset-title>
    <div class="list">
        <ng-container *ngFor="let vehicle of vehiclesRandom">
            <div class="vehicle" [routerLink]="'/ueber-uns/fahrzeuge/' + vehicle.vehicleUuid">
                <img [src]="vehicle.previewImage">
                <div class="text">
                    <h3>{{ vehicle.name }}</h3>
                    <p>{{ vehicle.description }}</p>
                </div>
            </div>
        </ng-container>
    </div>
</section>