import { Component, Inject, Optional, PLATFORM_ID } from '@angular/core';
import { Response } from 'express';
import { environment as cfg } from 'src/environments/environment';
import { isPlatformServer } from '@angular/common';

import { RESPONSE } from 'server.token';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(RESPONSE) private response: Response
  ) {
    if (isPlatformServer(this.platformId)) {
      this.response?.status(404);
    }
  }

  cfg = cfg;

}
