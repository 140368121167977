import { Component, OnInit } from '@angular/core';
import { environment as cfg } from 'src/environments/environment';
import { YearlyReportService } from 'src/app/services/yearly-report.service';
import { YearlyReport } from 'src/app/dtos/yearly-report';

@Component({
  selector: 'app-yearly-reports',
  templateUrl: './yearly-reports.component.html',
  styleUrls: ['./yearly-reports.component.scss']
})
export class YearlyReportsComponent implements OnInit {

  constructor(
    public yearlyReportService: YearlyReportService
  ) { }

  cfg = cfg;
  reports: YearlyReport[];

  ngOnInit(): void {
      this.getReports();
  }

  getReports() {
    this.yearlyReportService.getYearlyReports().subscribe((data: YearlyReport[]) => {
      this.reports = data;
    });
  }

  openReport(uuid: string) {
    window.open(`${cfg.domain.cdn}feuerwehr/yearlyreport/${uuid}/yearly-report.pdf`, '_blank');
  }
}
