import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as cfg } from 'src/environments/environment';
import { SingleEntryNaviagtion } from '../dtos/default';
import { Observable } from 'rxjs';
import { Page } from '../dtos/page';
import { NewsLight, News } from '../dtos/news';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getNewsList(page: number = 0, limit: number = 40): Observable<NewsLight[]> {
    var parameter = '?';
    parameter += `page=${page}&limit=${limit}`;

    return this.httpClient.get<NewsLight[]>(`${cfg.domain.api}feuerwehr/news/list${parameter}`);
  }

  getNews(uuid: string): Observable<News> {
    return this.httpClient.get<News>(`${cfg.domain.api}feuerwehr/news?uuid=${uuid}`)
  }

  public getNewsPage(page: number = 0, limit: number = 40): Observable<Page> {
    var parameter = '?';

    parameter += `page=${page}&limit=${limit}`;

    return this.httpClient.get<Page>(`${cfg.domain.api}feuerwehr/news/list/page${parameter}`);
  }

  public getNavigation(uuid: string): Observable<SingleEntryNaviagtion> {
    return this.httpClient.get<SingleEntryNaviagtion>(`${cfg.domain.api}feuerwehr/news/navigation?uuid=${uuid}`);
  }

  getWarnings(): Observable<NewsLight[]> {
    return this.httpClient.get<NewsLight[]>(`${cfg.domain.api}feuerwehr/news/warning`);
  }

}
