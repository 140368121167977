import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'asset-rtf',
  templateUrl: './rtf.component.html',
  styleUrl: './rtf.component.scss'
})
export class RtfComponent {
  @Input() public text: string = "";
  @Input() public gallery: string[] = [];
  @Input() public view: string = "text"; // text, gallery, all
  @Input() public base: string = "";
  @Input() public smallView: boolean = false;

  public _gallery = {
    "currentImage": "",
    "view": false,
  }

  @HostListener('click', ['$event'])
  onClick(event: any) {
    if (event.target.classList == "t___img") {
      this.openGallery(event.target.src);
    }
  }

  openGallery(image: string) {
    this._gallery.currentImage = image;
    this._gallery.view = true;
  }

  getGalleryData(event: any) {
    this._gallery.currentImage = '';
    this._gallery.view = event.view;
  }
}
