import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as cfg } from 'src/environments/environment';
import { WastlOperation } from '../dtos/wastl';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WastlService {

  public url = cfg.domain.api + 'wastl/';

  constructor(
    private httpClient: HttpClient
  ) {}

  getOperations(id: string = '', city: string = ''): Observable<WastlOperation[]> {

    var parameter = '?';
    if(id) {
      parameter += `id=${id}&`;
    }
    if(city) {
      parameter += `city=${city}`;
    }
      return this.httpClient.get<WastlOperation[]>(this.url + 'operation/active' + parameter);
  }

}
