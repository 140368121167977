<asset-title-bar
    [image]="'history'"
    [title]="'Geschichte'">
</asset-title-bar>

<div class="content" *ngIf="history">
    <p [innerHTML]="history.data | textStyle:'feuerwehr/data/history/'" class="dataText"></p>
    <div class="gallery">
        <a class="image" *ngFor="let image of history.gallery">
            <img [src]="image" (click)="openGallery(image)"/>
        </a>
    </div>
</div>
<view-gallery
    *ngIf="history"
    [gallery]="history.gallery"
    [currentImage]="gallery.currentImage"
    [view]="gallery.view"
    (dataEmit)="getGalleryData($event)"></view-gallery>