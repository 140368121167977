import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Page } from 'src/app/dtos/page';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { environment as cfg } from 'src/environments/environment';
import { NewsLight } from 'src/app/dtos/news';
import { NewsService } from 'src/app/services/news.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ArticlePreview } from 'src/app/dtos/article';
import { ArticlePreviewService } from 'src/app/services/mapper/article-preview.service';


@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrl: './news.component.scss'
})
export class NewsComponent implements OnInit {

  constructor(
    private router: Router,
    private newsService: NewsService,
    private activatedRoute: ActivatedRoute,
    private articlePreviewService: ArticlePreviewService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  public cfg = cfg;

  public limit: number = 36;
  public page: Page = new Page(0, 1, 0, 0);

  public articles: ArticlePreview[] = [];
  public latestArticle: ArticlePreview = new ArticlePreview();

  ngOnInit(): void {
    this.articles = [];
    this.activatedRoute.data.subscribe(data => {
      this.loadContent(data['articles'].data, data['latest'].news, data['articles'].page);
    });
  }

  loadContent(articles: ArticlePreview[] | null = null, latest: ArticlePreview | null = null, page: Page | null = null) {
    this.articles = [];
    if(!articles) {
      this.newsService.getNewsList( this.page.currentPage, this.limit)
      .subscribe((response: NewsLight[]) => {
          response.forEach((news: NewsLight) => {
            this.articles.push(this.articlePreviewService.news(news));
          });
          this.loadPage();
      },
      (error) => {
          console.log(error);
      });
    } else {
      if(page) {
        this.page = page;
      }
      if(latest) {
        this.latestArticle = latest;
      }
      articles = articles.filter((article) => article.uuid !== this.latestArticle.uuid);
      this.articles = articles;
    }
  }

  loadPage() {
    this.newsService.getNewsPage(this.page.currentPage, this.limit).subscribe(
      (response: Page) => {
        this.page = response;
        this.page.currentPage = response.currentPage;
      });
  }

  dateToString(date: string): string {
    var dateObj = new Date(date);
    var dateString = dateObj.toLocaleDateString("de-AT", { year: 'numeric', month: 'long', day: 'numeric' });

    return dateString;
  }  
  
  pageSelect(page: number = 1): void {
    this.articles = [];
    this.page.currentPage = page;
    this.router.navigate([], {
      queryParams: {
        page: page
      },
      queryParamsHandling: 'merge',
    });
    this.loadContent();
  }

  getVisiblePages(): number[] {
    const returnArray: number[] = [];
    if (this.page.allPages < 7) {
      for (let i = 1; i <= this.page.allPages; i++) {
        returnArray.push(i);
      }
    } else {
      if (this.page.currentPage < 4) {
        for (let i = 1; i <= 5; i++) {
          returnArray.push(i);
        }
        returnArray.push(-1);
        returnArray.push(this.page.allPages);
      } else if (this.page.currentPage > this.page.allPages - 4) {
        returnArray.push(1);
        returnArray.push(-1);
        for (let i = this.page.allPages - 4; i <= this.page.allPages; i++) {
          returnArray.push(i);
        }
      } else {
        returnArray.push(1);
        returnArray.push(-1);
        for (let i = this.page.currentPage - 2; i <= this.page.currentPage + 2; i++) {
          returnArray.push(i);
        }
        returnArray.push(-1);
        returnArray.push(this.page.allPages);
      }
    }

    return returnArray;
  }
}
