import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as cfg } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Page } from '../dtos/page';
import { Vehicle } from '../dtos/vehicle';
import { Event, EventLight } from '../dtos/event';
import { SingleEntryNaviagtion } from '../dtos/default';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getEvents(page: number = 0, limit: number = 40): Observable<EventLight[]> {
    return this.httpClient.get<EventLight[]>(`${cfg.domain.api}feuerwehr/event/list?page=${page}&limit=${limit}`);
  }

  getEvent(id: string, type: string = 'uuid'): Observable<Event> {
    if(type == 'id') {
      return this.httpClient.get<Event>(`${cfg.domain.api}feuerwehr/event?id=${id}`);
    } else {
      return this.httpClient.get<Event>(`${cfg.domain.api}feuerwehr/event?uuid=${id}`);
    }
  }

  public getEventsPage(page: number = 0, limit: number = 40): Observable<Page> {
    var parameter = '?';

    parameter += `page=${page}&limit=${limit}`;

    return this.httpClient.get<Page>(`${cfg.domain.api}feuerwehr/event/list/page${parameter}`);
  }

  public getNavigation(uuid: string): Observable<SingleEntryNaviagtion> {
    return this.httpClient.get<SingleEntryNaviagtion>(`${cfg.domain.api}feuerwehr/event/navigation?uuid=${uuid}`);
  }
}
