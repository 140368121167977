import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, ViewChild, Renderer2, PLATFORM_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { environment as cfg } from 'src/environments/environment';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  
  @ViewChild("nav") navbar: ElementRef;

  public isMenuCollapsed = true;
  public cfg = cfg;
  public navigationColor: string = "default";

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    router.events.subscribe(() => {
      this.scrollFunction();
      this.navClasses();
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {this.scrollFunction()}, 0);
  
      this.renderer.listen('document', 'click', (event) => {
        const clickedElement = event.target as HTMLElement;
        if(clickedElement.tagName == "A") {
          this.isMenuCollapsed = true;
          clickedElement.blur();
        }
      });
    }
  }

  toggleMenu() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
  }

  @HostListener('window:scroll')
  scrollFunction() {
    if (isPlatformBrowser(this.platformId)) {
      if(this.router.url == "/") {
        if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
          this.renderer.addClass(this.navbar.nativeElement, 'navScrollDown');
          this.renderer.removeClass(this.navbar.nativeElement, 'navScrollTop');
        } else {
          this.renderer.addClass(this.navbar.nativeElement, 'navScrollTop');
          this.renderer.removeClass(this.navbar.nativeElement, 'navScrollDown');
        }
      } else {
        this.renderer.addClass(this.navbar.nativeElement, 'navScrollDown');
        this.renderer.removeClass(this.navbar.nativeElement, 'navScrollTop');
      }
    }
  }

  navClasses() {
    var segments = this.router.url.split("/");
    if(segments[1] == "jugend") {
      this.renderer.addClass(this.navbar.nativeElement, 'youth');
    } else {
      this.renderer.removeClass(this.navbar.nativeElement, 'youth');
    }

    if(this.isMenuCollapsed) {
      this.renderer.addClass(this.navbar.nativeElement, 'nav_open');
    } else {
      this.renderer.removeClass(this.navbar.nativeElement, 'nav_open');
    }
  }

  tabNavigation(dest: string): void {
    if(isPlatformBrowser(this.platformId)) {
      switch (dest) {
        case 'main':
          const element = this.elementRef.nativeElement.nextElementSibling.nextElementSibling.firstChild;
          element.setAttribute('tabindex', '0');
          element.focus();
          element.removeAttribute('tabindex');
          break;
      
        default:
          break;
      }
    }
  }
}
