import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { environment as cfg, environment } from 'src/environments/environment';
import { AnalyticService } from './services/analytic.service';
import { WebAnalytic } from './dtos/analytic';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { SsrService } from './services/ssr.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private analyticService: AnalyticService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private title: Title,
    private ssrService: SsrService
  ) { }

  private analyticsData: WebAnalytic = new WebAnalytic();
  private analyticsTimeout: any;

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.helloMessage();
      this.loadCss();

      this.initStatisticData();
      this.analyticsTimeout = setTimeout(() => {
        this.statistic();
      }, 1000);
  
      this.router.events.subscribe(() => {
        clearTimeout(this.analyticsTimeout);
        this.analyticsTimeout = setTimeout(() => {
          this.statistic();
        }, 1000);
      });
    } else {
      this.ssrService.setDefault();
      this.ssrService.setKeywords(environment.meta.keywords);
    }
  }

  loadCss() {
    const head = document.getElementsByTagName('head')[0];

    // Poppins
    const poppins = document.createElement('link');
    poppins.rel = 'stylesheet';
    poppins.type = 'text/css';
    poppins.href = cfg.domain.api + '../lt/google/fonts/Poppins';
    head.appendChild(poppins);

    // google Icons
    const googleIcons = document.createElement('link');
    googleIcons.rel = 'stylesheet';
    googleIcons.type = 'text/css';
    googleIcons.href = cfg.domain.api + '../lt/google/icons';
    head.appendChild(googleIcons);
  }

  helloMessage() {
    const style = {
      title: 'font-family: monospace; font-size: 30px; font-weight: 600;',
      text: 'font-family: monospace; font-size: 15px; font-weight: 200;',
      disclaimer: 'font-family: monospace; font-size: 10px; font-weight: 100;'
    };
    
    console.log(
      '%c\n🔥🔥FF Perchtoldsdorf - Website🔥🔥\n\n' +
      '%cWillkommen auf der Website! 👩‍🚒👨‍🚒\n\n\n' +
      '%cby JPromi' +
      '\n\n\n\n\n',
      style.title, style.text, style.disclaimer
    );
  }

  initStatisticData() {
    this.analyticsData.path = '';
    this.analyticsData.device = this.getOperatingSystem();
  }

  statistic() {
    if(this.analyticsData.path != this.router.url) {
      this.analyticsData.path = this.router.url;
  
      this.analyticService.sendAnalytic(this.analyticsData).subscribe();
    }
  }

  private getOperatingSystem(): string {
    var userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/windows/i.test(userAgent)) {
        return "Windows";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) || /iPad|iPhone|iPod/.test(navigator.platform)) {
      return "iOS";
    }

    if (/macintosh|mac os x/i.test(userAgent)) {
      return "MacOS";
    }

    if (/linux/i.test(userAgent)) {
      return "Linux";
    }

    return "unknown";
}

}
