import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID, Pipe, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'popup-share',
  templateUrl: './popup-share.component.html',
  styleUrl: './popup-share.component.scss',
  animations: [
    trigger('openClose', [
      state('open', style({
        transform: 'scale(1)',
        opacity: 1
      })),
      state('closed', style({
        transform: 'scale(0.8)',
        opacity: 0
      })),
      transition('open => closed', [
        animate('250ms')
      ]),
      transition('closed => open', [
        animate('250ms')
      ]),
    ]),
  ],
})
export class PopupShareComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  @Input() title: string = "";
  @Input() url: string = window.location.href;
  @Input() description: string = "";
  @Input() previewImage: string = "";
  @Input() view: boolean = false;
  @Output() viewChange = new EventEmitter<boolean>();

  // get element by id #popup-share
  @ViewChild('urlValueCopy') urlValueCopy: ElementRef

  public svg: { [key: string]: SafeHtml | null } = {
    "facebook": null,
    "whatsapp": null,
    "x": null
  }
  public animationClosed: boolean = true;

  ngOnInit(): void {
    this.getIcons();
  }

  animationDone(event: any) {
    if (event.toState === 'closed') {
      this.animationClosed = true;
    } else {
      this.animationClosed = false;
    }
  }

  closePopup(): void {
    this.view = false;
    this.viewChange.emit(this.view);
  }

  // share
  getWhatsappLink(): string {
    let base = "https://api.whatsapp.com/send?text=";

    let link = "";

    if(this.title) {
      link = `*${this.title}*`;
    }

    if(this.description) {
      link += "\n" + this.description;
    }

    link += "\n\n" + this.url;

    return base + encodeURIComponent(link);
  }

  getFacebookLink(): string {
    let base = "https://www.facebook.com/dialog/feed?";
    let link = `app_id=${environment.facebookAppId}&`;
    link += "display=popup"
    link += `&link=${encodeURIComponent(this.url)}`;

    return base + link;
  }

  getXLink(): string {
    let base = "https://twitter.com/intent/tweet?";

    let link = "";
    let text = "";

    if(this.title) {
      text = `${this.title}\n\n`;
    }

    link += `text=${encodeURIComponent(text)}`;

    link += `&url=${encodeURIComponent(this.url)}`;

    return base + link;
  }

  getMailLink(): string {
    let base = "mailto:";
    let link = "";

    let subject = "";
    if(this.title) {
      subject = this.title;
    }

    let body = "";
    if(this.description) {
      body = this.description + "\n\n";
    }

    link += `?subject=${encodeURIComponent(subject)}`;
    link += `&body=${encodeURIComponent(body + this.url)}`;

    return base + link;
  }

  checkShareAPI(): boolean {
    if(isPlatformBrowser(this.platformId)) {
      return navigator.share !== undefined;
    } else {
      return false;
    }
  }

  shareContent() {
    if (navigator.share) {
      navigator.share({
        title: this.title,
        url: this.url
      }).then(() => {
      })
      .catch(console.error);
    } else {
      console.log('Web Share API not supported in your browser.');
    }
  }

  copyInput() {
    this.urlValueCopy.nativeElement.select();
    document.execCommand('copy');
  }

  private getIcons(): void {
    let icons = [
      { name: "facebook", var: "facebook" },
      { name: "whatsapp", var: "whatsapp" },
      { name: "x", var: "x" }
    ]

    icons.forEach(icon => {
      this.httpClient.get(`/assets/other/brand/${icon.name}.svg`, {responseType: 'text'}).subscribe(data => {
        this.svg[icon.var] = this.sanitizer.bypassSecurityTrustHtml(data);
      })
    });
  }
}
