import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Data, DataFiredepartmentTask } from 'src/app/dtos/data';
import { DataService } from 'src/app/services/data.service';
import { environment as cfg } from 'src/environments/environment';

@Component({
  selector: 'app-firedepartment-information',
  templateUrl: './firedepartment-information.component.html',
  styleUrls: ['./firedepartment-information.component.scss']
})
export class FiredepartmentInformationComponent implements OnInit {
  
    constructor(
      public dataService: DataService,
      public elementRef: ElementRef,
      public renderer: Renderer2
    ) { }

    public firedepartmentInformation: Data;
    public cfg = cfg;
    public gallery = {
      "currentImage": "",
      "view": false,
    }
    public taskGallery = {
      "currentImage": "",
      "view": false,
    }
    public taskGalleryImages: string[] = [];

    public tasks: DataFiredepartmentTask[] = [];

    ngOnInit(): void {
      this.loadData();
      this.getTasks();
    }

    loadData() {
      this.dataService.getData('firedepartment-information').subscribe((data: Data) => {
        this.firedepartmentInformation = data;
      });
    }

    getTasks() {
      this.dataService.getFiredepartmentTasks().subscribe(
        (data: DataFiredepartmentTask[]) => {
          this.tasks = data;
          this.tasks.forEach((task: DataFiredepartmentTask) => {
            if (task.previewImage) {
              this.taskGalleryImages.push(task.previewImage);
            }
          });
        }
      );
    }

    openGallery(image: string) {
      this.gallery.currentImage = image;
      this.gallery.view = true;
    }
  
    getGalleryData(event: any) {
      this.gallery.currentImage = '';
      this.gallery.view = event.view;
    }

    openTaskGallery(image: string) {
      this.taskGallery.currentImage = image;
      this.taskGallery.view = true;
    }

    getTaskGalleryData(event: any) {
      this.taskGallery.currentImage = '';
      this.taskGallery.view = event.view;
    }
}
