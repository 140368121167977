import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

interface DatePickerEventData {
  year: number;
  month: number;
  datePickerOpen: boolean;
  send: boolean;
}

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  @Input() year: number;
  @Input() month: number;
  @Input() datePickerOpen: boolean = false;
  @Output() dataEmit = new EventEmitter<DatePickerEventData>();

  public send: boolean = false;

  public yearPick: number[] = [];

  public months = [
    {
      name: 'Januar',
      number: 1
    },
    {
      name: 'Februar',
      number: 2
    },
    {
      name: 'März',
      number: 3
    },
    {
      name: 'April',
      number: 4
    },
    {
      name: 'Mai',
      number: 5
    },
    {
      name: 'Juni',
      number: 6
    },
    {
      name: 'Juli',
      number: 7
    },
    {
      name: 'August',
      number: 8
    },
    {
      name: 'September',
      number: 9
    },
    {
      name: 'Oktober',
      number: 10
    },
    {
      name: 'November',
      number: 11
    },
    {
      name: 'Dezember',
      number: 12
    }
  ]

  ngOnInit(): void {
    this.generateYears();
  }

  generateYears() {
    for(let i = 0; i < 5; i++) {
      this.yearPick.push(new Date().getFullYear() - i);
    }
  }

  changeYear(year: number) {
    this.year = year;
  }

  changeMonth(month: number) {
    this.month = month;
  }

  closeDatePicker(send: boolean = true) {
    this.datePickerOpen = false;
    this.emitData(send);
  }

  checkYear(year: number): boolean {
    if(year == this.year) {
      return true;
    } else {
      return false;
    }
  }

  checkMonth(month: number): boolean {
    if(month == this.month) {
      return true;
    } else {
      return false;
    }
  }

  emitData(send: boolean = true) {
    this.dataEmit.emit({year: this.year, month: this.month, datePickerOpen: this.datePickerOpen, send: send});
  }
}
