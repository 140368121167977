import { Directive, ElementRef, HostListener, Input, Renderer2, OnChanges, SimpleChanges, OnInit } from '@angular/core';

@Directive({
  selector: '[tabTrap]'
})
export class TabTrapDirective implements OnChanges, OnInit {
  @Input() tabTrap: boolean = true;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.updateTabIndex();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tabTrap']) {
      this.updateTabIndex();
    }
  }

  @HostListener('keydown.tab', ['$event'])
  manageTab(event: KeyboardEvent) {
    if (!this.tabTrap) {
      return;
    }

    const focusableElements = this.getFocusableElements();
    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];

    if (event.shiftKey) {
      if (document.activeElement === firstElement) {
        event.preventDefault();
        lastElement.focus();
      }
    } else {
      if (document.activeElement === lastElement) {
        event.preventDefault();
        firstElement.focus();
      }
    }
  }

  private updateTabIndex() {
    const focusableElements = this.getFocusableElements();
    focusableElements.forEach((element: HTMLElement) => {
      if (this.tabTrap) {
        this.renderer.removeAttribute(element, 'tabindex');
      } else {
        this.renderer.setAttribute(element, 'tabindex', '-1');
      }
    });
  }

  private getFocusableElements(): NodeListOf<HTMLElement> {
    return this.elementRef.nativeElement.querySelectorAll(
      'a, button, input, textarea, select, [tabindex]:not([tabindex="-1"])'
    );
  }
}
