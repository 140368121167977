import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Exercise, ExerciseLight } from '../dtos/exercise';
import { environment as cfg } from 'src/environments/environment';
import { Page } from '../dtos/page';
import { SingleEntryNaviagtion } from '../dtos/default';

@Injectable({
  providedIn: 'root'
})
export class ExerciseService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getExercisesList(page: number = 0, limit: number = 40): Observable<ExerciseLight[]> {
    var parameter = '?';
    parameter += `page=${page}&limit=${limit}`;

    return this.httpClient.get<ExerciseLight[]>(`${cfg.domain.api}feuerwehr/exercise/list${parameter}`);
  }

  getExercise(uuid: string): Observable<Exercise> {
    return this.httpClient.get<Exercise>(`${cfg.domain.api}feuerwehr/exercise?uuid=${uuid}`)
  }

  public getExercisesPage(page: number = 0, limit: number = 40): Observable<Page> {
    var parameter = '?';

    parameter += `page=${page}&limit=${limit}`;

    return this.httpClient.get<Page>(`${cfg.domain.api}feuerwehr/exercise/list/page${parameter}`);
  }

  public getNavigation(uuid: string): Observable<SingleEntryNaviagtion> {
    return this.httpClient.get<SingleEntryNaviagtion>(`${cfg.domain.api}feuerwehr/exercise/navigation?uuid=${uuid}`);
  }
}
