import { Address, Link } from "./default";

export class FfjRegistration {
    constructor(
        public firstname: string = '',
        public lastname: string = '',
        public email: string = '',
        public phone: string = '',
        public gender: string = '',
        public birthdate: string = '',
        public address: Address = new Address('', '', '', ''),
        public about: string = '',
    ) {}
}

export class FfjReportLight {
    constructor(
        public reportUuid: string,
        public date: string,
        public title: string,
        public report: string,
        public previewImage: string
    ) {}
}

export class FfjReport {
    constructor(
        public reportUuid: string,
        public date: string,
        public title: string,
        public report: string,
        public previewImage: string,
        public link: Link[],
        public gallery: [image: string],
    ) {}
}

export class FfjLeader {
    constructor(
        public leaderUuid: string,
        public title: string,
        public name: string,
        public position: string,
        public positionYear: string,
        public entryYear: string,
        public quote: string,
        public image: string,
        public background: string
    ) {}
}