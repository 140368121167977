<app-error *ngIf="error"></app-error>

<table>
    <thead>
        <tr>
            <th>
                <div class="printheader">
                    <div>
                        <img [src]="enviroment.domain.cdn + 'assets/logo/ff_pdorf.svg'">
                    </div>
                    <div>
                        <ng-container [ngSwitch]="article.type">
                            <h3 *ngSwitchCase="'op'">Einsatz</h3>
                            <h3 *ngSwitchCase="'nw'">News</h3>
                            <h3 *ngSwitchCase="'ev'">Veranstaltung</h3>
                            <h3 *ngSwitchCase="'ex'">Übung</h3>
                            <h3 *ngSwitchCase="'fj'">FFJ Bericht</h3>
                        </ng-container>
                    </div>
                </div>
            </th>
        </tr>
    </thead>
    <tfoot>
        <tr>
            <th>
                <div class="printfooter">
                    <div>
                        <p class="disclaimer">{{ printInformations.domain }}</p>
                    </div>
                </div>
            </th>
        </tr>
    </tfoot>
    <tbody>
        <tr>
            <td>
                <div class="article" [class.operation]="article.type == 'op'"  *ngIf="article && !error">
                    <div class="navigation" *ngIf="article.navigation">
                        <a (click)="navigateTo(article.navigation.previous)" *ngIf="article.navigation.previous" class="previous">
                            <mat-icon>
                                chevron_left
                            </mat-icon>
                            Vorheriger
                        </a>
                        <a (click)="navigateTo(article.navigation.next)" *ngIf="article.navigation.next" class="next">
                            Nächster
                            <mat-icon>
                                chevron_right
                            </mat-icon>
                        </a>
                    </div>
                    <img class="top" [src]="article.previewImage">
                    <article class="content">

                        <div class="text">
                            <h2>{{ article.title }}</h2>
                            <p *ngIf="article.headline" [ngClass]="{ 'short': article.headline && article.description }">{{ article.headline }}</p>
                            <p>{{ article.description }}</p>
                            
                            <div class="gallery" *ngIf="article.gallery.length > 0">
                                <ng-container *ngFor="let image of article.gallery">
                                    <a (click)="openGallery(image)">
                                        <img [src]="image">
                                    </a>
                                </ng-container>
                            </div>
                            <p *ngIf="article.credit"> © {{ article.credit }} </p>
                        </div>

                        <div class="information">
                            <div class="i-main">
                                <h2>Informationen</h2>
                                <ng-container *ngIf="article.type == 'op'">
                                    <div *ngIf="article.opAlarm">
                                        <h4>Alarmstufe: </h4>
                                        <p [ngClass]="['op-type', article.opAlarm.type.toLocaleLowerCase()]"> {{ article.opAlarm.type + article.opAlarm.level }} </p>
                                    </div>

                                    <div *ngIf="article.opElkosReport">
                                        <h4>Alarmierung: </h4>
                                        <p>{{ article.opElkosReport }}</p>
                                    </div>
                                </ng-container>

                                <div *ngIf="article.date">
                                    <h4>Datum: </h4>
                                    <p>{{ convertDate(article.date) }}</p>
                                </div>

                                <div *ngIf="article.evDates && article.evDates.length > 0">
                                    <h4>Datum: </h4>
                                    <ng-container *ngFor="let date of article.evDates">
                                        <p>{{ convertDate(date.dateFrom) }} - {{ convertDate(date.dateTo, 'min') }}</p>
                                    </ng-container>
                                </div>

                                <div *ngIf="article.nwStatus">
                                    <h4>Status: </h4>
                                    <span>
                                        <ng-container [ngSwitch]="article.nwStatus">
                                            <p *ngSwitchCase="'ok'" class="ok type">Information</p>
                                            <p *ngSwitchCase="'warning'" class="warning type">Warnung</p>
                                            <p *ngSwitchCase="'critical'" class="critical type">Kritisch</p>
                                        </ng-container>
                                    </span>
                                </div>

                                <div *ngIf="article.location">
                                    <h4>Ort: </h4>
                                    <p>{{ article.location }}</p>
                                </div>

                                <div *ngIf="article.opVehicles && article.opVehicles.length > 0">
                                    <h4>Fahrzeuge: </h4>
                                    <span>
                                        <ng-container *ngFor="let vehicle of article.opVehicles">
                                            <a [routerLink]="'/ueber-uns/fahrzeuge/' + vehicle.vehicleUuid">
                                                {{ vehicle.name }}
                                            </a>
                                        </ng-container>
                                    </span>
                                </div>

                                <div *ngIf="article.link && article.link.length > 0">
                                    <h4>Links: </h4>
                                    <span>
                                        <ng-container *ngFor="let link of article.link">
                                            <a [href]="link.url" *ngIf="!urlIconService.getIcon(link.url)" target="_blank">
                                                {{ link.title }}
                                            </a>
                                            <a [href]="link.url" *ngIf="urlIconService.getIcon(link.url)" target="_blank">
                                                <svg id="Logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 700" *ngIf="urlIconService.getIcon(link.url) == 'facebook'">
                                                    <g id="Logo-2" data-name="Logo">
                                                    <path id="F" class="cls-1" d="m487.08,456.95l19.64-106.95h-114.32v-37.83c0-56.52,22.17-78.26,79.56-78.26,17.83,0,32.17.43,40.43,1.3v-96.96c-15.65-4.35-53.91-8.7-76.09-8.7-116.95,0-170.87,55.22-170.87,174.35v46.09h-72.17v106.95h72.17v232.74c27.08,6.72,55.39,10.31,84.55,10.31,14.35,0,28.5-.88,42.4-2.56v-240.48h94.68Z"/>
                                                    </g>
                                                </svg>

                                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" *ngIf="urlIconService.getIcon(link.url) == 'instagram'">
                                                    <path class="cls-1" d="M295.42,6c-53.2,2.51-89.53,11-121.29,23.48-32.87,12.81-60.73,30-88.45,57.82S40.89,143,28.17,175.92c-12.31,31.83-20.65,68.19-23,121.42S2.3,367.68,2.56,503.46,3.42,656.26,6,709.6c2.54,53.19,11,89.51,23.48,121.28,12.83,32.87,30,60.72,57.83,88.45S143,964.09,176,976.83c31.8,12.29,68.17,20.67,121.39,23s70.35,2.87,206.09,2.61,152.83-.86,206.16-3.39S799.1,988,830.88,975.58c32.87-12.86,60.74-30,88.45-57.84S964.1,862,976.81,829.06c12.32-31.8,20.69-68.17,23-121.35,2.33-53.37,2.88-70.41,2.62-206.17s-.87-152.78-3.4-206.1-11-89.53-23.47-121.32c-12.85-32.87-30-60.7-57.82-88.45S862,40.87,829.07,28.19c-31.82-12.31-68.17-20.7-121.39-23S637.33,2.3,501.54,2.56,348.75,3.4,295.42,6m5.84,903.88c-48.75-2.12-75.22-10.22-92.86-17-23.36-9-40-19.88-57.58-37.29s-28.38-34.11-37.5-57.42c-6.85-17.64-15.1-44.08-17.38-92.83-2.48-52.69-3-68.51-3.29-202s.22-149.29,2.53-202c2.08-48.71,10.23-75.21,17-92.84,9-23.39,19.84-40,37.29-57.57s34.1-28.39,57.43-37.51c17.62-6.88,44.06-15.06,92.79-17.38,52.73-2.5,68.53-3,202-3.29s149.31.21,202.06,2.53c48.71,2.12,75.22,10.19,92.83,17,23.37,9,40,19.81,57.57,37.29s28.4,34.07,37.52,57.45c6.89,17.57,15.07,44,17.37,92.76,2.51,52.73,3.08,68.54,3.32,202s-.23,149.31-2.54,202c-2.13,48.75-10.21,75.23-17,92.89-9,23.35-19.85,40-37.31,57.56s-34.09,28.38-57.43,37.5c-17.6,6.87-44.07,15.07-92.76,17.39-52.73,2.48-68.53,3-202.05,3.29s-149.27-.25-202-2.53m407.6-674.61a60,60,0,1,0,59.88-60.1,60,60,0,0,0-59.88,60.1M245.77,503c.28,141.8,115.44,256.49,257.21,256.22S759.52,643.8,759.25,502,643.79,245.48,502,245.76,245.5,361.22,245.77,503m90.06-.18a166.67,166.67,0,1,1,167,166.34,166.65,166.65,0,0,1-167-166.34" transform="translate(-2.5 -2.5)"/>
                                                </svg>

                                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.48 85.04" *ngIf="urlIconService.getIcon(link.url) == 'youtube'">
                                                    <path class="cls-1" d="m118.95,13.28c-1.4-5.23-5.51-9.34-10.74-10.74C98.73,0,60.74,0,60.74,0c0,0-37.99,0-47.46,2.54C8.05,3.94,3.94,8.05,2.54,13.28,0,22.75,0,42.52,0,42.52c0,0,0,19.77,2.54,29.24,1.4,5.23,5.51,9.34,10.74,10.74,9.47,2.54,47.46,2.54,47.46,2.54,0,0,37.99,0,47.46-2.54,5.23-1.4,9.34-5.51,10.74-10.74,2.54-9.47,2.54-29.24,2.54-29.24,0,0,0-19.77-2.54-29.24ZM48.59,60.74V24.3l31.56,18.22-31.56,18.22Z"/>
                                                </svg>
                                                <ng-container *ngIf="urlIconService.getIcon(link.url) == 'default'">
                                                    {{ link.title }}
                                                </ng-container>
                                            </a>
                                        </ng-container>
                                    </span>
                                </div>
                            </div>

                            <div class="settings">
                                <button (click)="popupShare = !popupShare">
                                    <mat-icon>share</mat-icon>
                                </button>
                                <button (click)="print()">
                                    <mat-icon>print</mat-icon>
                                </button>
                            </div>
                        </div>

                    </article>

                    <div class="printgallery">
                        <img *ngFor="let image of article.gallery" [src]="image">
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</table>

<popup-share
    *ngIf="isBrowser"
    [(view)]="popupShare"
    [url]="getUrl()"
    [title]="article.title"
    [description]="article.headline || article.description"></popup-share>

<view-gallery
    *ngIf="article && isBrowser"
    [gallery]="article.gallery"
    [currentImage]="gallery.currentImage"
    [view]="gallery.view"
    (dataEmit)="getGalleryData($event)"></view-gallery>