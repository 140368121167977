<asset-title-bar
    [image]="'vehicles'"
    [title]="'Fahrzeuge'">
</asset-title-bar>

<div class="info">

</div>

<div class="vehicles">

    <h3 *ngIf="vehiclesUse.length > 0">Fahrzeuge</h3>
    <div class="list" *ngIf="vehiclesUse.length > 0">
        <ng-container *ngFor="let vehicle of vehiclesUse">
            <div class="vehicle" [routerLink]="['/ueber-uns/fahrzeuge/', vehicle.vehicleUuid]">
                <div class="preview">
                    <div class="background">
                        <img [src]="vehicle.previewImage">
                    </div>
                </div>
                <div class="text">
                    <h2>{{ vehicle.name }}</h2>
                </div>
            </div>
        </ng-container>
    </div>

    <h3 *ngIf="accessoryUse.length > 0">Zubehör</h3>
    <div class="list" *ngIf="accessoryUse.length">
        <ng-container *ngFor="let vehicle of accessoryUse">
            <div class="vehicle" [routerLink]="['/ueber-uns/fahrzeuge/', vehicle.vehicleUuid]">
                <div class="preview">
                    <div class="background">
                        <img [src]="vehicle.previewImage">
                    </div>
                </div>
                <div class="text">
                    <h2>{{ vehicle.name }}</h2>
                    <!-- <p>{{ vehicle.designation }}</p> -->
                </div>
            </div>
        </ng-container>
    </div>

    <h3 *ngIf="oldStuff.length > 0">Ausgeschiedenes</h3>
    <div class="list" *ngIf="oldStuff.length > 0">
        <ng-container *ngFor="let vehicle of oldStuff">
            <div class="vehicle" [routerLink]="['/ueber-uns/fahrzeuge/', vehicle.vehicleUuid]">
                <div class="preview">
                    <div class="background">
                        <img [src]="vehicle.previewImage">
                    </div>
                </div>
                <div class="text">
                    <h2>{{ vehicle.name }}</h2>
                    <!-- <p>{{ vehicle.designation }}</p> -->
                </div>
            </div>
        </ng-container>
    </div>

</div>