<asset-title-bar
    [image]="'firedepartment-information'"
    [title]="'Aufgabe der Feuerwehr'">
</asset-title-bar>

<div class="content" *ngIf="firedepartmentInformation">
     <asset-rtf
        [text]="firedepartmentInformation.data"
        [gallery]="firedepartmentInformation.gallery"
        [base]="'firedepartment-information'"
        [view]="'text'">
    </asset-rtf>

    <div class="challanges">
        <h1>Die Herausforderungen</h1>
        <div class="list">
            <div class="challange" *ngFor="let task of tasks">
                <img [src]="task.previewImage" (click)="openTaskGallery(task.previewImage)">
                <div class="text">
                    <h3> {{ task.title }} </h3>
                    <p> {{ task.description }} </p>
                </div>
            </div>
        </div>
    </div>

    <asset-rtf [view]="'gallery'" [gallery]="firedepartmentInformation.gallery"></asset-rtf>
</div>

<view-gallery
    *ngIf="tasks"
    [gallery]="taskGalleryImages"
    [currentImage]="taskGallery.currentImage"
    [view]="taskGallery.view"
    (dataEmit)="getTaskGalleryData($event)"></view-gallery>