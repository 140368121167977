export class CookieSettings {
    constructor(
        public googleMaps: boolean = true,
        public cInf: CookieInformation = new CookieInformation(),
    ) {}
}

export class CookieInformation {
    constructor(
        public date: Date = new Date(),
    ) {}
}