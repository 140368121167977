import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ArticlePreview } from 'src/app/dtos/article';
import { Page } from 'src/app/dtos/page';

@Component({
  selector: 'asset-article-preview-list',
  templateUrl: './article-preview-list.component.html',
  styleUrl: './article-preview-list.component.scss'
})
export class ArticlePreviewListComponent {
  @Input() articles: ArticlePreview[] = [];

  @Input() page: Page = new Page(0, 1, 0, 0);
  @Output() pageChange = new EventEmitter<Page>();

  @Input() allowList: boolean = false;

  @Input() viewList: boolean = false;
  @Output() viewListChange = new EventEmitter<boolean>();

  // data processing
  opType(type: string): string {
    return type.toLowerCase();
  }

  // page
  getVisiblePages(): number[] {
    const returnArray: number[] = [];
    if (this.page.allPages < 7) {
      for (let i = 1; i <= this.page.allPages; i++) {
        returnArray.push(i);
      }
    } else {
      if (this.page.currentPage < 4) {
        for (let i = 1; i <= 5; i++) {
          returnArray.push(i);
        }
        returnArray.push(-1);
        returnArray.push(this.page.allPages);
      } else if (this.page.currentPage > this.page.allPages - 4) {
        returnArray.push(1);
        returnArray.push(-1);
        for (let i = this.page.allPages - 4; i <= this.page.allPages; i++) {
          returnArray.push(i);
        }
      } else {
        returnArray.push(1);
        returnArray.push(-1);
        for (let i = this.page.currentPage - 2; i <= this.page.currentPage + 2; i++) {
          returnArray.push(i);
        }
        returnArray.push(-1);
        returnArray.push(this.page.allPages);
      }
    }

    return returnArray;
  }

  goToPage(page: number) {
    this.page.currentPage = page;
    this.pageChange.emit(this.page);
  }

  formatDate(date: string): string {
    var dateObj = new Date(date);
    var dateString = dateObj.toLocaleDateString("de-AT", { year: 'numeric', month: 'long', day: 'numeric' });

    return dateString;
  } 
}
