import { Component, OnInit } from '@angular/core';
import { Page } from 'src/app/dtos/page';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { environment as cfg } from 'src/environments/environment';
import { FfjService } from 'src/app/services/ffj.service';
import { FfjReportLight } from 'src/app/dtos/ffj';
import { ArticlePreview } from 'src/app/dtos/article';
import { ArticlePreviewService } from 'src/app/services/mapper/article-preview.service';

@Component({
  selector: 'app-ffj-reports',
  templateUrl: './ffj-reports.component.html',
  styleUrl: './ffj-reports.component.scss'
})
export class FfjReportsComponent implements OnInit {
  constructor(
    private router: Router,
    private ffjService: FfjService,
    private activatedRoute: ActivatedRoute,
    private articlePreviewService: ArticlePreviewService
  ) { }

  public cfg = cfg;

  public limit: number = 36;
  public page: Page = new Page(0, 1, 0, 0);

  public articles: ArticlePreview[] = [];

  ngOnInit(): void {
    this.articles = [];
    this.activatedRoute.data.subscribe(data => {
      this.loadContent(data['articles'].data, data['articles'].page);
    });
  }

  loadContent(articles: ArticlePreview[] | null = null, page: Page | null = null) {
    this.articles = [];
    if(!articles) {
      this.ffjService.getReportsList( this.page.currentPage, this.limit)
      .subscribe((response: FfjReportLight[]) => {
          response.forEach((report: FfjReportLight) => {
            this.articles.push(this.articlePreviewService.ffjReport(report));
          });
          this.loadPage();
      },
      (error) => {
          console.log(error);
      });
    } else {
      if(page) {
        this.page = page;
      }
      this.articles = articles;
    }
  }

  loadPage() {
    this.ffjService.getReportPage(this.page.currentPage, this.limit).subscribe(
      (response: Page) => {
        this.page = response;
        this.page.currentPage = response.currentPage;
      });
  }
  
  pageSelect(page: number = 1): void {
    this.articles = [];
    this.page.currentPage = page;
    this.router.navigate([], {
      queryParams: {
        page: page
      },
      queryParamsHandling: 'merge',
    });
    this.loadContent();
  }
}
