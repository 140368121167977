import { ResolveFn } from '@angular/router';
import { OperationLight } from '../dtos/operation';
import { inject } from '@angular/core';
import { OperationService } from '../services/operation.service';
import { ArticlePreview, ArticlePreviewObject } from '../dtos/article';
import { catchError, map, of, switchMap } from 'rxjs';
import { ArticlePreviewService } from '../services/mapper/article-preview.service';
import { Page } from '../dtos/page';

export const operationsResolver: ResolveFn<ArticlePreviewObject | null> = (route, state) => {
  var year = parseInt(route.queryParamMap.get('year') ?? new Date().getFullYear().toString());
  var month = parseInt(route.queryParamMap.get('month') ?? "0");
  var page = parseInt(route.queryParamMap.get('page') ?? "1");
  var type = route.queryParamMap.get('type') ?? '';
  var limit = 36;

  const service = inject(OperationService);
  const mapper = inject(ArticlePreviewService);
  return service.getOperations(year, month, type, page, limit).pipe(
    switchMap((data) => {
      return service.getOperationPage(year, month, type, page, limit).pipe(
        map((pageData) => {
          var articles = data.map((operation) => {
            return mapper.operation(operation);
          });
          
          let articlePreviewObject = new ArticlePreviewObject(articles, pageData);
          return articlePreviewObject;
        }),
        catchError((error) => {
          console.log(error);
          return of(null);
        })
      );
    })
  );
};
