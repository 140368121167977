<p
    *ngIf="view === 'text' || view === 'all'"
    [innerHTML]="text | textStyle:'feuerwehr/data/' + base +  '/'"
    [class.small]="smallView">
</p>

<div class="gallery" *ngIf="view === 'gallery' || view === 'all'">
    <a class="image" *ngFor="let image of gallery">
        <img [src]="image" (click)="openGallery(image)"/>
    </a>
</div>

<view-gallery
    [gallery]="gallery"
    [currentImage]="_gallery.currentImage"
    [view]="_gallery.view"
    (dataEmit)="getGalleryData($event)"></view-gallery>
