<ng-container *ngIf="url">
    <a class="block-title" [routerLink]="url" [style.width.rem]="width">
        <span></span>
        <p>{{ title }}</p>
    </a>
</ng-container>

<ng-container *ngIf="!url">
    <a class="block-title nolink" [style.width.rem]="width">
        <span></span>
        <p>{{ title }}</p>
    </a>
</ng-container>