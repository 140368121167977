import { ResolveFn } from '@angular/router';
import { Exercise } from '../dtos/exercise';
import { inject } from '@angular/core';
import { ExerciseService } from '../services/exercise.service';
import { catchError, map, of, switchMap } from 'rxjs';
import { Article } from '../dtos/article';
import { ArticleService } from '../services/mapper/article.service';

export const exerciseResolver: ResolveFn<Article | null> = (route, state) => {
  const service = inject(ExerciseService);
  const mapper = inject(ArticleService);
  const id = route.paramMap.get('uuid') ?? '';

  return service.getExercise(id).pipe(
    switchMap((exercise) => {
      
      return service.getNavigation(exercise.exerciseUuid).pipe(
        
        map((navigation) => {
          return mapper.exercise(exercise, navigation);
        })
      );
    }),
    // Fehlerbehandlung
    catchError((error) => {
      if (error.status === 404) {
        return of(null);
      } else {
        throw error;
      }
    })
  );
};
