import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { OperationService } from '../services/operation.service';
import { catchError, of, switchMap, map } from 'rxjs';
import { Article } from '../dtos/article';
import { ArticleService } from '../services/mapper/article.service';

export const operationResolver: ResolveFn<Article | null> = (route, state) => {
  const service = inject(OperationService);
  const mapper = inject(ArticleService);
  const id = route.paramMap.get('uuid') ?? '';

  return service.getOperation(id, 'uuid').pipe(
    switchMap((operation) => {
      
      return service.getOperationNavigation(operation.operationUuid).pipe(
        
        map((navigation) => {
          return mapper.operation(operation, navigation);
        })
      );
    }),
    // Fehlerbehandlung
    catchError((error) => {
      if (error.status === 404) {
        return of(null);
      } else {
        throw error;
      }
    })
  );
};