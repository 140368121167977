import { ResolveFn } from '@angular/router';
import { EventService } from '../services/event.service';
import { ArticlePreviewService } from '../services/mapper/article-preview.service';
import { inject } from '@angular/core';
import { catchError, map, of, switchMap } from 'rxjs';
import { ArticlePreviewObject } from '../dtos/article';

export const eventsResolver: ResolveFn<ArticlePreviewObject | null> = (route, state) => {
  var page = parseInt(route.queryParamMap.get('page') ?? "1");
  var limit = 36;

  const service = inject(EventService);
  const mapper = inject(ArticlePreviewService);
  return service.getEvents(page, limit).pipe(
    switchMap((data) => {
      return service.getEventsPage(page, limit).pipe(
        map((pageData) => {
          var articles = data.map((event) => {
            return mapper.event(event);
          });
          
          let articlePreviewObject = new ArticlePreviewObject(articles, pageData);
          return articlePreviewObject;
        }),
        catchError((error) => {
          console.log(error);
          return of(null);
        })
      );
    })
  );
};
