import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LegalImprint } from '../dtos/legal';

@Injectable({
  providedIn: 'root'
})
export class LegalService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private _url = environment.domain.api + 'feuerwehr/legal';

  getImprint(): Observable<LegalImprint> {
    return this.httpClient.get<LegalImprint>(this._url + '/imprint');
  }
}
