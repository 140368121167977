<div class="banner" *ngIf="!cookieAccepted">
    <h1>Cookies</h1>
    <p>Diese Website verwendet Cookies, um Ihr Surferlebnis zu verbessern und um unseren Datenverkehr zu analysieren.</p>

    <div class="advanced">
        <h2>Erweiterte Einstellungen</h2>

        <div class="group">
            <h4>System relevante Cookies</h4>

            <span>
                
                <label class="slider">
                    <input type="checkbox" [checked]="true" [disabled]="true">
                    <div class="sl-slider"></div>
                </label>
                <p>Analysen</p>
            </span>

            <span>
                <label class="slider">
                    <input type="checkbox" [checked]="true" [disabled]="true">
                    <div class="sl-slider"></div>
                </label>
                <p>Server Log</p>
            </span>

        </div>

        <div class="group">
            <h4>Google</h4>

            <span>
                <label class="slider">
                    <input type="checkbox" name="googleMaps" [(ngModel)]="cookieSettings.googleMaps">
                    <div class="sl-slider"></div>
                </label>
                <p>Google Maps</p>
            </span>

        </div>

    </div>
    
    <div class="btn">
        <button class="" (click)="declineCookies()">Alle Ablehnen</button>
        <button class="accept" (click)="acceptCookies()">Bestätigen</button>
    </div>
</div>
<div class="void" *ngIf="!cookieAccepted"></div>