<a [routerLink]="article.url">
    <div class="preview">
        <div class="info">
            <h3 *ngIf="article.opAlarm" [classList]="operationType(article.opAlarm.type)">{{ article.opAlarm.type }}{{ article.opAlarm.level }}</h3>
        </div>
        <div class="background">
            <img [src]="article.previewImage">
        </div>
    </div>
    <div class="text">
        <h2>{{ article.title }}</h2>
        <p class="description">{{ article.description }}</p>
        <p class="date">{{ formatDate(article.date) }}</p>
    </div>
</a>