import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as cfg } from 'src/environments/environment';
import { Operation, OperationLight, OperationStatistics } from '../dtos/operation';
import { Observable } from 'rxjs';
import { Page } from '../dtos/page';
import { SingleEntryNaviagtion } from '../dtos/default';

@Injectable({
  providedIn: 'root'
})
export class OperationService {

  constructor(
    private httpClient: HttpClient
  ) { }

  public getOperation(id: string, type: string = 'uuid'): Observable<Operation> {
    if(type == 'id') {
      return this.httpClient.get<Operation>(`${cfg.domain.api}feuerwehr/operation?id=${id}`);
    } else {
      return this.httpClient.get<Operation>(`${cfg.domain.api}feuerwehr/operation?uuid=${id}`);
    }
  }

  public getOperations(year: number = 0, month: number = 0, type: string = '', page: number = 0, limit: number = 40): Observable<OperationLight[]> {
    var parameter = '?';
    if(year != 0) {
      parameter += `year=${year}&`;
    }

    if(month != 0) {
      parameter += `month=${month}&`;
    }

    if(type != '') {
      parameter += `type=${type}&`;
    }

    parameter += `page=${page}&limit=${limit}`;

    if(year != 0 || month != 0) {
      return this.httpClient.get<OperationLight[]>(`${cfg.domain.api}feuerwehr/operation/listfilter${parameter}`);
    } else {
      return this.httpClient.get<OperationLight[]>(`${cfg.domain.api}feuerwehr/operation/list${parameter}`);
    }
  }

  public getOperationStatistics(year: number = 0): Observable<OperationStatistics> {
    var parameter = '?';
    if(year != 0) {
      parameter += `year=${year}`;
    }
    return this.httpClient.get<any>(`${cfg.domain.api}feuerwehr/operation/statistic${parameter}`);
  }

  public getOperationPage(year: number = 0, month: number = 0, type: string = '', page: number = 0, limit: number = 40): Observable<Page> {
    var parameter = '?';
    if(year != 0) {
      parameter += `year=${year}&`;
    }

    if(month != 0) {
      parameter += `month=${month}&`;
    }

    if(type != '') {
      parameter += `type=${type}&`;
    }
    
    parameter += `page=${page}&limit=${limit}`;

    return this.httpClient.get<Page>(`${cfg.domain.api}feuerwehr/operation/listfilter/page${parameter}`);
  }

  public getOperationNavigation(uuid: string): Observable<SingleEntryNaviagtion> {
    return this.httpClient.get<SingleEntryNaviagtion>(`${cfg.domain.api}feuerwehr/operation/navigation?uuid=${uuid}`);
  }
}
