import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Command, MemberCounter, SubjectArea } from '../dtos/member';
import { environment as cfg } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  constructor(
    public httpClient: HttpClient
  ) { }

  getCommands(): Observable<Command[]> {
    return this.httpClient.get<Command[]>(`${cfg.domain.api}feuerwehr/member/commands`);
  }

  getSubjectAreas(): Observable<SubjectArea[]> {
    return this.httpClient.get<SubjectArea[]>(`${cfg.domain.api}feuerwehr/member/subjectareas`);
  }

  getCounter(): Observable<MemberCounter[]> {
    return this.httpClient.get<MemberCounter[]>(`${cfg.domain.api}feuerwehr/member/counter`);
  }
}
