import { ResolveFn } from '@angular/router';
import { Event } from '../dtos/event';
import { EventService } from '../services/event.service';
import { inject } from '@angular/core';
import { catchError, map, of, switchMap } from 'rxjs';
import { Article } from '../dtos/article';
import { ArticleService } from '../services/mapper/article.service';

export const eventResolver: ResolveFn<Article | null> = (route, state) => {
  const service = inject(EventService);
  const mapper = inject(ArticleService);
  const id = route.paramMap.get('uuid') ?? '';

  return service.getEvent(id, 'uuid').pipe(
    switchMap((event) => {
      
      return service.getNavigation(event.eventUuid).pipe(
        
        map((navigation) => {
          return mapper.event(event, navigation);
        })
      );
    }),
    // Fehlerbehandlung
    catchError((error) => {
      if (error.status === 404) {
        return of(null);
      } else {
        throw error;
      }
    })
  );
};
