import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as cfg } from 'src/environments/environment';
import { FfjLeader, FfjRegistration, FfjReport, FfjReportLight } from '../dtos/ffj';
import { Page } from '../dtos/page';
import { SingleEntryNaviagtion } from '../dtos/default';

@Injectable({
  providedIn: 'root'
})
export class FfjService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getLeaderList(): Observable<FfjLeader[]> {
    return this.httpClient.get<FfjLeader[]>(`${cfg.domain.api}feuerwehrjugend/leader`);
  }

  registration(formular: FfjRegistration): Observable<boolean> {
    return this.httpClient.post<boolean>(`${cfg.domain.api}feuerwehrjugend/registration`, formular);
  }

  getReportsList(page: number = 0, limit: number = 40): Observable<FfjReportLight[]> {
    var parameter = '?';
    parameter += `page=${page}&limit=${limit}`;

    return this.httpClient.get<FfjReportLight[]>(`${cfg.domain.api}feuerwehrjugend/report/list${parameter}`);
  }

  getReport(uuid: string): Observable<FfjReport> {
    return this.httpClient.get<FfjReport>(`${cfg.domain.api}feuerwehrjugend/report?uuid=${uuid}`)
  }

  public getReportPage(page: number = 0, limit: number = 40): Observable<Page> {
    var parameter = '?';

    parameter += `page=${page}&limit=${limit}`;

    return this.httpClient.get<Page>(`${cfg.domain.api}feuerwehrjugend/report/list/page${parameter}`);
  }

  public getNavigation(uuid: string): Observable<SingleEntryNaviagtion> {
    return this.httpClient.get<SingleEntryNaviagtion>(`${cfg.domain.api}feuerwehrjugend/report/navigation?uuid=${uuid}`);
  }
}
