<asset-title-bar
    [image]="'operations'"
    [title]="'Einsätze'">
</asset-title-bar>

<div class="info">
    <div class="type-info">

        <div class="type" id="statInfoBrand" (mouseenter)="statisticHover('statBrand', true)" (mouseleave)="statisticHover('statBrand', false)">
            <mat-icon class="b">local_fire_department</mat-icon>
            <h3>Brand</h3>
            <p>Brandverdacht, Fahrzeugbrand, Wohnhausbrand,...</p>
        </div>

        <div class="type" id="statInfoTechnisch" (mouseenter)="statisticHover('statTechnisch', true)" (mouseleave)="statisticHover('statTechnisch', false)">
            <mat-icon class="t">construction</mat-icon>
            <h3>Technisch</h3>
            <p>Tierrettung, Verkehrsunfall, Menschenrettung,...</p>
        </div>

        <div class="type" id="statInfoSchadstoff" (mouseenter)="statisticHover('statSchadstoff', true)" (mouseleave)="statisticHover('statSchadstoff', false)">
            <mat-icon class="s">science</mat-icon>
            <h3>Schadstoff</h3>
            <p>Gasaustritt, Chemieunfall, Umweltgefährdung,...</p>
        </div>

        <div class="type" id="statInfoOther" (mouseenter)="statisticHover('statOther', true)" (mouseleave)="statisticHover('statOther', false)">
            <mat-icon class="other">engineering</mat-icon>
            <h3>Sonstiges</h3>
            <p>Unterstützung anderer Einsatzorganisationen</p>
        </div>

        <div class="statistic">
            <h4>Statistik {{ year }}</h4>
            <div class="all">
                <h3 [countUp]="operationStatistics.allOperations" [duration]="2500"></h3>
                <p>Einsätze</p>
            </div>
            <div class="category">
                <h3 [countUp]="operationStatistics.fireOperations" [duration]="2500" class="b" id="statBrand" (mouseenter)="statisticHover('statInfoBrand', true)" (mouseleave)="statisticHover('statInfoBrand', false)"> {{ operationStatistics.fireOperations }} </h3>
                <h3 [countUp]="operationStatistics.technicalOperations" [duration]="2500" class="t" id="statTechnisch" (mouseenter)="statisticHover('statInfoTechnisch', true)" (mouseleave)="statisticHover('statInfoTechnisch', false)"> {{ operationStatistics.technicalOperations }} </h3>
                <h3 [countUp]="operationStatistics.pollutantOperations" [duration]="2500" class="s" id="statSchadstoff" (mouseenter)="statisticHover('statInfoSchadstoff', true)" (mouseleave)="statisticHover('statInfoSchadstoff', false)"> {{ operationStatistics.pollutantOperations }} </h3>
                <h3 [countUp]="operationStatistics.otherOperations" [duration]="2500" class="other" id="statOther" (mouseenter)="statisticHover('statInfoOther', true)" (mouseleave)="statisticHover('statInfoOther', false)"> {{ operationStatistics.otherOperations }} </h3>
            </div>
        </div>
    </div>

    
</div>

<div class="operations">
    <div class="header">
        <h2>
            {{ getDate() }}
        </h2>
        
    </div>
    <div class="options">
        <div class="left">
            <h4>Filter</h4>
            <button (click)="openDatePicker()" class="s2">
                {{ getDate() }}
                <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <date-picker
                    [year]="year"
                    [month]="month"
                    [datePickerOpen]="datePickerOpen"
                    (dataEmit)="getDatePickData($event)"
            >
            </date-picker>

            <mat-select class="s2" [(ngModel)]="filterTypeActive" (selectionChange)="filterTypeChange()">
                <mat-select-trigger>
                    <span>
                        {{ filterListTypeGet() }}
                    </span>
                </mat-select-trigger>
                <mat-option *ngFor="let type of filterTypeList" [value]="type.type" class="auto-width-option">
                    <span> {{ type.friendlyName }} </span>
                </mat-option>
            </mat-select>
        </div>

        <div class="right">
            <button class="s1" (click)="listTable = !listTable" *ngIf="listTable" title="Listen Ansicht">
                <mat-icon>view_list</mat-icon>
            </button>
            <button class="s1" (click)="listTable = !listTable" *ngIf="!listTable" title="Kachel Ansicht">
                <mat-icon>view_module</mat-icon>
            </button>
        </div>
    </div>
    
    <asset-article-preview-list
        [articles]="articles"
        [(page)]="page"
        (pageChange)="pageSelect(page.currentPage + 1)"
        [(viewList)]="listTable">
    </asset-article-preview-list>
</div>