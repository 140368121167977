import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InformationList } from 'src/app/dtos/default';
import { Dimension, Vehicle } from 'src/app/dtos/vehicle';
import { SsrService } from 'src/app/services/ssr.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss']
})

export class VehicleComponent implements OnInit {

  constructor(
    public vehicleService: VehicleService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private ssrService: SsrService
  ) { }

  public error: boolean = false;
  public vehicle: Vehicle;
  public gallery = {
    "currentImage": "",
    "view": false
  }
  public galleryImages: string[];
  public vehicleInformationList: InformationList[];

  private keywords: string[] = ["Fahrzeug"];

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(data => {
      if(data['vehicle']) {
        this.vehicle = data['vehicle'];
        if(isPlatformBrowser(this.platformId)) {
          this.vehicleInformation();
        }
  
        this.ssrService.setArticle(this.vehicle.name, this.vehicle.description, this.vehicle.previewImage);
  
        this.keywords.push(this.vehicle.name);
        this.keywords.push(this.vehicle.designation);
        this.keywords.push(...environment.meta.keywords);
        this.ssrService.setKeywords(this.keywords);
      } else {
        this.errorHandeler();
      }
    },
    error => {
      console.log(error);
      this.errorHandeler();
    });
  }

  openGallery(image: string, type: string = 'gallery') {
    this.gallery.currentImage = image;
    if(type == 'gallery') {
      this.galleryImages = this.vehicle.gallery;
    } else {
      this.galleryImages = [image];
    }
    this.gallery.view = true;
  }

  getGalleryData(event: any) {
    this.gallery.currentImage = '';
    this.gallery.view = event.view;
  }

  vehicleInformation() {
    this.vehicleInformationList = [
      this.vehicleInformationAdd('Funkrufname', this.vehicle.designation),
      this.vehicleInformationAdd('Marke', this.vehicle.chassis),
      this.vehicleInformationAdd('Type', this.vehicle.designationType),
      this.vehicleInformationAdd('Aufbauersteller', this.vehicle.bodyManufacturer),
      this.vehicleInformationAdd('Baujahr', this.vehicle.constructionYear),
      this.vehicleInformationAdd('Modell', this.vehicle.vehicleModel),
      this.vehicleInformationAdd('Antrieb', this.vehicle.gear),
      this.vehicleInformationAdd('Motorleistung', this.vehicle.performance, 'text', 'PS'),
      this.vehicleInformationAdd('Besatzung', this.vehicle.crew),
      this.vehicleInformationAdd('Eigengewicht', this.vehicle.weight, 'weight'),
      this.vehicleInformationAdd('L/B/H', this.vehicle.dimension, 'dimensionLHW'),
      this.vehicleInformationAdd('Zuladung', this.vehicle.loading, 'weight'),
      this.vehicleInformationAdd('Wassertank', this.vehicle.watertank, 'text', 'l'),
      this.vehicleInformationAdd('Schaumtank', this.vehicle.foamtank, 'text', 'l'),
      this.vehicleInformationAdd('Rettungshöhe', this.vehicle.rescueHeight, 'dimension'),
      this.vehicleInformationAdd('Hubkraft', this.vehicle.liftingCapacity, 'text', 'mto'),
      this.vehicleInformationAdd('Status', this.vehicle.inUse ? '' : 'Außer Dienst'),
    ];
  }

  vehicleInformationAdd(title: string, data: string | number | Dimension, type: string = 'text', suffix: string = ''): InformationList {
    if(data) {
      if(type == 'weight') {
        data = this.calcWeight(data as number);
      } else if(type == 'dimension') {
        data = this.calcDimension(data as number);
      } else if(type == 'dimensionLHW') {
        data = this.calcDimensionLHW(data as Dimension);
      }
      if(suffix) {
        var object = new InformationList(title, data as string + ' ' + suffix);
      } else {
        var object = new InformationList(title, data as string);
      }
    } else {
      var object = new InformationList(title, '');
    }

    return object;
  }

  calcWeight(weight: number): string {
    if(weight != null) {
      if(weight > 1000) {
        var calc = weight / 1000;
        var calcString = calc.toFixed(2) + ' t';
        return calcString.replace('.', ',');
      } else {
        return weight + ' kg';
      }
    } else {
      return '';
    }
    
  }

  calcDimension(dimension: number): string {
    if(dimension != null) {
      if(dimension > 100) {
        var calculation: number = dimension / 100;
        var calcString = calculation.toFixed(2) + ' m';
        return calcString.replace('.', ',');
      } else {
        return dimension + ' cm';
      }
    } else {
      return '';
    }
  }

  calcDimensionLHW(dimension: Dimension): string {
    if(dimension.width != 0 && dimension.height != 0 && dimension.length != 0) {
      return this.calcDimension(dimension.length) + ' / ' + this.calcDimension(dimension.width) + ' / ' + this.calcDimension(dimension.height);
    } else {
      return '';
    }
  }


  errorHandeler() {
    // this.router.navigate(['/ueber-uns/fahrzeuge']);
    this.error = true;
  }
}
