import { Component, Input } from '@angular/core';
import { ArticlePreview } from 'src/app/dtos/article';

@Component({
  selector: 'article-preview-large',
  templateUrl: './article-preview-large.component.html',
  styleUrl: './article-preview-large.component.scss'
})
export class ArticlePreviewLargeComponent {
  @Input() article: ArticlePreview = new ArticlePreview();
  @Input() title: string = '';

  formatDate(date: string): string {
    let d = new Date(date);
    return d.toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' });
  }
}
