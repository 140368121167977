<asset-title-bar
    [image]="'yearly-report'"
    [title]="'Jahresbericht'">
</asset-title-bar>

<div class="content">
    <div class="list">
        <div class="report" *ngFor="let report of reports" (click)="openReport(report.yearlyReportUuid)">
            <div class="preview">
                <img [src]="report.previewImage">
            </div>
            <h2> {{ report.title }} </h2>
        </div>
    </div>
</div>