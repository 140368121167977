<asset-title-bar [image]="'ffj'" [title]="'Feuerwehrjugend'"></asset-title-bar>

<div class="informations" *ngIf="data">
    <p [innerHTML]="data.data | textStyle:'feuerwehr/data/history/'" class="dataText"></p>
    <div class="gallery" *ngIf="data.gallery.length > 0">
        <a class="image" *ngFor="let image of data.gallery">
            <img [src]="image" (click)="openGallery(image)"/>
        </a>
    </div>
</div>

<div class="leaders">
    <div class="list">
        <ng-container *ngFor="let leader of leaderList">
            <div class="leader">
                <div class="img">
                    <img [src]="leader.image" class="port">
                    <div class="background">
                        <img [src]="leader.background">
                    </div>
                </div>
                <div class="text">
                    <div class="name">
                        <h2>{{ leader.title }}</h2>
                        <h1>{{ leader.name }}</h1>
                    </div>
                    <h4 class="position"> {{ leader.position }} </h4>
                    <div class="text">
                        <p *ngIf="leader.positionYear">Seit {{ leader.positionYear }}</p>
                        <p *ngIf="leader.entryYear">Aktiv seit {{ leader.entryYear }} </p>
                        <p class="quote" *ngIf="leader.quote">
                            <span>&bdquo;</span>{{ leader.quote }}<span>&rdquo;</span>
                        </p>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div class="reports">
    <asset-title [title]="'Letzte Jugendstunden'" [url]="'/jugend/bericht'" [width]="20"></asset-title>
    <div class="list">
        <ng-container *ngFor="let report of reportArticle">
            <asset-article-preview [article]="report"></asset-article-preview>
        </ng-container>        
    </div>
</div>

<div class="registration" *ngIf="registrationActive">
    <div class="text">
        <h2>Werde Mitglied der Feuerwehr Jugend</h2>
        <p>Wir freuen uns über dein Interesse an der Feuerwehrjugend. Über das Formular kannst du mit unserem Jugendbetreuerteam in Kontakt treten.</p>
    </div>

    <form>
        <div class="section">
            <div>
                <h3>Persönliche Daten</h3>
                <label>
                    <p class="required">Vorname:</p>
                    <input type="text" name="firstname" [(ngModel)]="registrationFormular.firstname">
                </label>
                <label>
                    <p class="required">Nachname:</p>
                    <input type="text" name="lastname" [(ngModel)]="registrationFormular.lastname">
                </label>
                <label>
                    <p class="required">Geburtsdatum:</p>
                    <input type="date" name="birthdate" [(ngModel)]="registrationFormular.birthdate">
                </label>
                <label>
                    <p class="required">Geschlecht:</p>
                    <!-- <input type="text" name="gender" [(ngModel)]="registrationFormular.gender"> -->
                    <mat-select name="gender" [(ngModel)]="registrationFormular.gender">
                        <mat-select-trigger>
                            <span class="input">
                                <ng-container [ngSwitch]="registrationFormular.gender">
                                    <span *ngSwitchCase="''"></span>
                                    <span *ngSwitchCase="'male'">Männlich</span>
                                    <span *ngSwitchCase="'female'">Weiblich</span>
                                    <span *ngSwitchCase="'diverse'">Divers</span>
                                </ng-container>
                            </span>
                        </mat-select-trigger>
                        <mat-option  [value]="''" class="auto-width-option">
                            <span></span>
                        </mat-option>
                        <mat-option  [value]="'male'" class="auto-width-option">
                            <span>Männlich</span>
                        </mat-option>
                        <mat-option  [value]="'female'" class="auto-width-option">
                            <span>Weiblich</span>
                        </mat-option>
                        <mat-option  [value]="'diverse'" class="auto-width-option">
                            <span>Divers</span>
                        </mat-option>
                    </mat-select>
                </label>
                <label>
                    <p class="required">E-Mail:</p>
                    <input type="email" name="email" [(ngModel)]="registrationFormular.email">
                </label>
                <label>
                    <p>Telefonnummer:</p>
                    <input type="tel" name="phone" [(ngModel)]="registrationFormular.phone">
                </label>
            </div>
            <div>
                <h3 class="required">Adresse</h3>
                <div class="address">
                    <span>
                        <input type="text" name="zip" [(ngModel)]="registrationFormular.address.zip" placeholder="PLZ">
                        <input type="text" name="city" [(ngModel)]="registrationFormular.address.city" placeholder="Stadt">
                    </span>
                    <span>
                        <input type="text" name="street" [(ngModel)]="registrationFormular.address.street" placeholder="Straße">
                        <input type="text" name="housenumber" [(ngModel)]="registrationFormular.address.housenumber" placeholder="Nummer">
                    </span>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="about">
                <h3>Über mich</h3>
                <textarea name="about" [(ngModel)]="registrationFormular.about"></textarea>
            </div>
            <div>
                <label class="disclaimer">
                    <div class="checkbox">
                        <input type="checkbox" name="dsgvo" [(ngModel)]="registrationDsgvo">
                        <span class="bg"></span>
                        <span class="check"></span>
                    </div>
                    <p class="required">Ich habe die <a href="/datenschutz" target="_blank">DSGVO</a> gelesen und ich bin damit einverstanden das all meine Daten verkauft werden dürfen!</p>
                </label>
            </div>
        </div>
        <div class="end">
            <button
                (click)="sendRegistraion()"
                [disabled]="
                    !(registrationDsgvo && 
                    registrationFormular.firstname &&
                    registrationFormular.lastname &&
                    registrationFormular.birthdate &&
                    registrationFormular.email &&
                    registrationFormular.gender &&
                    registrationFormular.address.zip &&
                    registrationFormular.address.city &&
                    registrationFormular.address.street &&
                    registrationFormular.address.housenumber)
                ">Senden</button>
        </div>
    </form>
</div>

<view-gallery
    *ngIf="data"
    [gallery]="data.gallery"
    [currentImage]="gallery.currentImage"
    [view]="gallery.view"
    (dataEmit)="getGalleryData($event)"></view-gallery>