import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebServiceService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private _url = environment.domain.api + 'system/service';

  checkService(service: string): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this._url}?service=${service}`);
  }
}
