import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'view-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  @Input() gallery: string[];
  @Input() currentImage: string;
  @Input() view: boolean = false;
  @Output() dataEmit = new EventEmitter<GalleryComponent>();

  ngOnInit(): void {
    
  }

  @HostListener('document:keydown', ['$event'])
  keyListener(event: any) {
    if(event.key == "Escape") {
      this.closeGallery();
    } else if (event.key == "ArrowRight") {
      this.nextImage();
    } else if (event.key == "ArrowLeft") {
      this.previousImage();
    }
  }

  isGallery(): boolean {
    if(this.gallery.length > 1) {
      return true;
    } else {
      return false;
    }
  }
  
  closeGallery(): void {
    this.view = false;
    this.dataEmit.emit(this);
  }

  nextImage(): void {
    if(this.gallery.indexOf(this.currentImage) == this.gallery.length - 1) {
      this.currentImage = this.gallery[0];
      return;
    } else {
      this.currentImage = this.gallery[this.gallery.indexOf(this.currentImage) + 1];
      return;
    }
  }

  previousImage(): void {
    if(this.gallery.indexOf(this.currentImage) == 0) {
      this.currentImage = this.gallery[this.gallery.length - 1];
      return;
    } else {
      this.currentImage = this.gallery[this.gallery.indexOf(this.currentImage) - 1];
      return;
    }
  }
}
