import { Component, OnInit } from '@angular/core';
import { environment as cfg } from 'src/environments/environment';
import { LegalcookieService } from 'src/app/services/legalcookie.service';
import { Loader } from '@googlemaps/js-api-loader';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(
    public legalcookieService: LegalcookieService,
  ) {}

  cfg = cfg;
  mapsLoaded = false;
  mapsDisabled = false;
  mapsDisabledMobile: boolean = false;

  mapsCenter: { [key: string]: google.maps.LatLngLiteral } = {
    fullscreen: { lat: 48.120574951171875, lng: 16.269935157226562 },
    mobile: { lat: 48.120574951171875, lng: 16.268936157226562 },
  }

  mapOptions: google.maps.MapOptions = {
    // mapId: cfg.googleMpasMapId,
    streetViewControl: false,
    mapTypeControl: false,
    mapTypeId: 'roadmap',
    styles: [
      {
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#bdbdbd"
          }
        ]
      },
      {
        "featureType": "landscape.man_made",
        "elementType": "geometry",
        "stylers": [
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "landscape.man_made",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#cccccc"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#bababa"
          },
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#ababab"
          },
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "poi.business",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi.business",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dadada"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#c9c9c9"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      }
    ],
    fullscreenControl: false,
    // scrollwheel: false,
    // scaleControl: false,
    zoomControl: false,
    disableDefaultUI: true,
    keyboardShortcuts: false,
    disableDoubleClickZoom: true,

    center: this.mapsCenter['fullscreen'],
    zoom: 17,
    maxZoom: 20,
    minZoom: 5,
    restriction: {
      latLngBounds: {
        north: 48.0,
        south: 48.3,
        west: 16.3,
        east: 16.0
      },
      strictBounds: true
    }
  }

  mapsLoader = new Loader(
    {
      apiKey: cfg.googleMapsApiKey,
      version: "weekly",
      language: "de",
      authReferrerPolicy: "origin",
    }
  );
  map: google.maps.Map;
  directionsService: google.maps.DirectionsService;
  directionsDisplay: google.maps.DirectionsRenderer;

  ngOnInit(): void {

    this.checkCookie();

    window.addEventListener('resize', () => {
      this.windowResize();
    });
  }

  checkCookie() {
    if(this.legalcookieService.checkIfServiceAllowed("googleMaps")) {
      this.initMap();
    } else {
      this.mapsLoaded = true;
      this.mapsDisabled = true;
    }
  }

  initMap(): void {
    this.mapsLoader.load().then(() => {
      this.map = new google.maps.Map(document.getElementById("map") as HTMLElement, this.mapOptions);

      this.directionsService = new google.maps.DirectionsService();
      this.directionsDisplay = new google.maps.DirectionsRenderer();
      this.directionsDisplay.setMap(this.map);
      this.directionsDisplay.setOptions(
        { 
          suppressMarkers: true,
          polylineOptions: {
            strokeColor: '#ff000050',
            strokeWeight: 10
          }
        }
      );
      this.map.setOptions({
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [{ visibility: 'off' }]
          }
        ]
      });
      this.initializeMap();
    });
  }

  private initializeMap(): void {
    this.mapsLoaded = true;

    const mapElement = document.getElementById('map');

    if (mapElement) {
      this.map = new google.maps.Map(mapElement, this.mapOptions);

      var marker = new google.maps.Marker({
        position: { lat: 48.120574951171875, lng: 16.268936157226562 },
        map: this.map,
        icon: {
          url: cfg.domain.cdn + 'assets/logo/ff_pdorf.svg',
          scaledSize: new google.maps.Size(70, 70),
        },
      });

      marker.addListener('click', () => {
        window.open('https://maps.app.goo.gl/BD93etWEPbMS1CwS7', '_blank');
      });
      this.windowResize();
    }
  }

  windowResize() {
    if(window.innerWidth < 750) {
      if(!this.mapsDisabled) {
        this.map.setCenter(this.mapsCenter['mobile']);
      }
      return;
    } else {
      if(!this.mapsDisabled) {
        this.map.setCenter(this.mapsCenter['fullscreen']);
      }
      return;
    }
  }

  openMapsLink() {
    window.open('https://maps.app.goo.gl/BD93etWEPbMS1CwS7', '_blank');
  }
}
